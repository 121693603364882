export function useBlockedInscriptionsIds(){
    const STORAGE_KEY = "BLOCKED_INSCRIPCIONS_IDS"

    function removeBlockedInscriptionsIds(){
        localStorage.removeItem(STORAGE_KEY)
    }

    function setBlockedInscriptionsIds(blockedInscriptionsIds: Number[]){
        localStorage.setItem(STORAGE_KEY,JSON.stringify(blockedInscriptionsIds))
    }

    function getBlockedInscriptionsIds(){
        const items = localStorage.getItem(STORAGE_KEY)
        return items ? JSON.parse(items): null 
    }

    return {
        removeBlockedInscriptionsIds,
        setBlockedInscriptionsIds,
        getBlockedInscriptionsIds
    }
    
}