import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

export default function useIsMobileSize() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  const size: any = useWindowSize();

  useEffect(() => {
      setIsMobileSize(size.width < 990);
  }, [size]);
  return isMobileSize;
}
