export default function AuthUserReducer(
    state: any = null,
    action: any
  ) {
    switch (action.type) {
      case "USER_SIGNIN":
        return action.payload;
      case "USER_SIGNOUT":
        return null;
      default: 
        return state
    }
  }
  
  AuthUserReducer.actions = (user:any = null) => ({
    signin: { type: "USER_SIGNIN", payload: user },
    signout: { type: "USER_SIGNOUT", payload: null },
  });
  