import Button from "@mui/material/Button";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import styled from "styled-components";
import CardContainer from "../../../../../components/containers/cardContainer/CardContainer";
import CardItemContainer from "../../../../../components/containers/cardItemContainer/CardItemContainer";
import Line from "../../../../../components/parts/line/Line";
import { EVENT_IMAGE_BASE_ROUTE, INSCRIPTION_PRINT_ROUTE } from "../../../../../hooks/api/useApi";
import useAuth from "../../../../../hooks/auth/useAuth";
import styles from "./EventTicketCard.module.css";
export default function EventTicketCard({ inscription, ...rest }: any) {
  const auth = useAuth()


  async function clickButton(inscription:any){
    console.log(inscription)
    const token = await auth.getUserToken()
    const newToken = btoa(token)
    const variablesPath = `${newToken}/${inscription.event.id}/${inscription.session.id}/${inscription.groupId}`
    const route = `${INSCRIPTION_PRINT_ROUTE}${variablesPath}`
    window.open(route)
  }


  return (
    <CardItemContainer style={{ marginTop:10, border: "1px solid lightgray", borderRadius:13 }} {...rest}>
      <CardItemContainer.FirstCol>
        <div className={styles.firstColContainer}>
          <div style={{ textTransform: "uppercase" }}>
            {moment(inscription.session.start).format("DD/MM/YYYY - HH:mm")}
          </div>
          <Line />
          {inscription.session.start !== inscription.session.end && (
            <div style={{ textTransform: "uppercase" }}>
              {moment(inscription.session.end).format("DD/MM/YYYY - HH:mm")}
            </div>
          )}
        </div>
      </CardItemContainer.FirstCol>
      <CardItemContainer.MiddleCol>
        <CardItemContainer.Image
          src={`${EVENT_IMAGE_BASE_ROUTE}${inscription.event.poster.filename}`}
          alt="Event"
        />
        <div className={styles.eventInfoContainer}>
          <div className={styles.eventInfoMainContainer}>
            <div className={styles.eventTextContainer}>
              <label
                className={`${styles.eventTextLabel} ${styles.titleLabel}`}
              >
                {inscription.event.name}
              </label>
            </div>
            <Line />
            <label className={`${styles.eventTextLabel} ${styles.infoLabel}`}>
              <span className={styles.placeText}>
                {inscription.session.place}
              </span>
              <span className={styles.placeText}>,</span>
              <span className={styles.placeText}>
                {inscription.session.city} {inscription.session.postalCode}
              </span>
              <span className={styles.placeText}>-</span>
              <span className={styles.placeText}>
                {inscription.session.state} {inscription.session.country}
              </span>
            </label>
          </div>
        </div>
      </CardItemContainer.MiddleCol>
      <CardItemContainer.LastCol>
        <Button variant="contained" className={styles.pdfButton} onClick={() => clickButton(inscription)}>
          <FaRegFilePdf size={18} color="#FFFFFF" />
        </Button>
      </CardItemContainer.LastCol>
    </CardItemContainer>
  );
}
