import { useDispatch, useSelector } from "react-redux";
import useIsMobileSize from "../../../hooks/windowSize/useIsMobileSize";
import useWindowSize from "../../../hooks/windowSize/useWindowSize";
import SidebarMobileOpened from "../../../reducers/sidebar/SidebarMobileOpenedReducer";
import styles from "./DefaultBodyContainer.module.css";
import sizes from "../../../constants/sizes";
import useBodySize from "../../../hooks/bodySize/useBodySize";
import FooterContainer from "../footerContainer/FooterContainer";
import React from "react";
export default function DefaultBodyContainer({
  style = {},
  children,
  sidebar = false,
  clientConfig = {},
  footer = true,
  ...rest
}: any) {
  const isMobileSize = useIsMobileSize();
  const showMobile = useSelector((state: any) => state.sidebarMobileOpened);
  const windowSize = useWindowSize();
  const bodySize = useBodySize();
  const dispatch = useDispatch();

  function bodyClicked() {
    if (isMobileSize && showMobile) {
      dispatch(SidebarMobileOpened.actions.hide);
    }
  }

  const bodyContainerStyle: any = {
    width: sidebar ? bodySize : "100%",
    opacity: isMobileSize && showMobile ? 0.3 : 1,
    marginTop: sizes.navbar.height,
    height: windowSize.height - sizes.navbar.height,
    backgroundColor: clientConfig.backgroundColor ?? "#F6F6F6",
  };

  if (clientConfig.backgroundImage) {
    bodyContainerStyle.backgroundImage = `url(${clientConfig.backgroundImage})`;
    bodyContainerStyle.backgroundRepeat = "no-repeat";
    bodyContainerStyle.backgroundSize = "cover";
  }

  return (
    <React.Fragment>
      <div
        className={styles.bodyContainer}
        style={{ ...bodyContainerStyle, ...style }}
        onClick={() => bodyClicked()}
        {...rest}
      >
        <div className={styles.paddingContainer}>
          {children}
        </div>

        {/* {footer && <FooterContainer />} */}

      </div>

    </React.Fragment>
  );
}
