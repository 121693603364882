export default function AuthUserRolesReducer(
    state: any = [],
    action: any
  ) {
    switch (action.type) {
      case "SET_USER_ROLES":
        return action.payload;
      case "REMOVE_USER_ROLES":
        return [];
      default:
        return state;
    }
  }
  
  AuthUserRolesReducer.actions = (roles:any = []) => ({
    setRoles: { type: "SET_USER_ROLES", payload: roles },
    removeRoles: { type: "REMOVE_USER_ROLES", payload: [] },
  });
  