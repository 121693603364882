import React, { useEffect, useState } from "react"
import LoaderContainer from "../../../../components/containers/loaderContainer/LoaderContainer"
import { useGetMyUpcomingTicketsLazyQuery, useGetMyUpcomingTicketsQuery } from "../../../../generated/graphql"
import EventTicketCard from "../components/eventTicketCard/EventTicketCard"

export default function UpcomingEvents(){
    const {data,loading,refetch}= useGetMyUpcomingTicketsQuery()
    const [inscriptions,setInscriptions] :any= useState([])

    // useEffect(() => {
    //    if(!loading){
    //     refetch()
    //    } 
    // })

    useEffect(() => {
        console.log(data)
        if(data && data.getMyUpcomingTickets){
            setInscriptions(data.getMyUpcomingTickets)
        }
    },[data,loading])



    return (
        <React.Fragment>
            {loading? 
                <LoaderContainer />:
                <React.Fragment>
                    {inscriptions.map((inscription:any,index:number) => (
                        <EventTicketCard inscription={inscription} key={index}/>
                    
                    ))}
                </React.Fragment>
            }
        </React.Fragment>
    )
}