const colors = {
    navbar:{
        backgroundColor: "#18A1E2",
        inactiveTextColor: "#FFFFFF",
        activeTextColor: "#FFFFFF",
        hoverColor: "#38C5B0",
        iconsColor: "#464646",
        borderBottomColor: "#EAEAEA"
    },
    sidebar:{
        backgroundColor: "#F2F2F2",
        parentBackgroundColorActive: "#38C5B0", 
        parentTextColorActive: "#FFFFFF", 
        backgroundActiveColor: "#dbdbdb",
        activeTextColor: "#38C5B0",
        inactiveTextColor: "#000000",
        logoContainerBorderColor: "#EAEAEA"
    },
    body:{
        textColor: '#757575'
    }
}

export default colors