import { Button } from "@mui/material";
import styled from "styled-components";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import colors from "../../../constants/colors";

export const StyledCardHeaderClientEvents = styled(CardItemContainer)<{clientconfig:any}>`
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: ${params => params.clientconfig.bodyBackgroundColor ?? "#FFFFFF"};
  color: ${params => params.clientconfig.bodyColor ?? colors.body.textColor};
`;

export const StyledButtonClientEvents = styled(Button)<{clientconfig:any}>`
  height: 43px;
  width: 100%;
  background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
  colors.navbar.backgroundColor};
  color: ${params => params.clientconfig.mainButtonTextColor ?? colors.navbar.activeTextColor};
  word-break: break-word;
  :hover {
    opacity: 0.8;
    background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
    colors.navbar.backgroundColor};
  }
`;
