import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DefaultBodyContainer from "../../../components/containers/bodyContainer/DefaultBodyContainer";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import LoaderContainer from "../../../components/containers/loaderContainer/LoaderContainer";
import Navbar from "../../../components/navigation/header/navbar/Navbar";
import Line from "../../../components/parts/line/Line";
import { usePublicClientEventsQuery } from "../../../generated/graphql";
import {
  CLIENT_BACKGROUND_IMAGE_BASE_ROUTE,
  CLIENT_LOGO_IMAGE_BASE_ROUTE,
} from "../../../hooks/api/useApi";
import { processEvent } from "../../../utils/eventsUtils";
import styles from "./ClientEvents.module.css";
import colors from "../../../constants/colors";
import moment from "moment";
import { StyledButtonClientEvents, StyledCardHeaderClientEvents } from "./ClientEventsStyleds";
import { HiOutlineChevronRight } from "react-icons/hi";

export default function ClientEvents() {
  moment.locale('ca')
  const params: any = useParams();
  const history = useHistory();
  const [events, setEvents]: any = useState([]);
  const [clientConfig, setClientConfig]: any = useState({});
  const [loadedPage, setLoadedPage] = useState(false);
  const { data, error, loading, refetch } = usePublicClientEventsQuery({
    variables: { clientCodename: params.clientCodename },
  });

  useEffect(() => {
    if(!loading){
      refetch();
    }
  },[]);

  useEffect(() => {
    if (!loading && data) {
      const res = data.getEventsClient;
      setClientConfig({
        id: res.id,
        name: res.name,
        codename: params.clientCodename,
        logo: res.logo
          ? `${CLIENT_LOGO_IMAGE_BASE_ROUTE}${res.logo.filename}`
          : "",
        navbarBackgroundColor: res.navbarBackgroundColor,
        navbarTextColor: res.navbarTextColor,
        bodyTextColor: res.bodyTextColor,
        backgroundImage: res.backgroundImage
          ? `${CLIENT_BACKGROUND_IMAGE_BASE_ROUTE}${res.backgroundImage.filename}`
          : "",
        backgroundColor: res.backgroundColor,
        bodyBackgroundColor: res.bodyBackgroundColor,
        mainButtonBackgroundColor: res.mainButtonBackgroundColor,
        mainButtonTextColor: res.mainButtonTextColor,
      });
      const newEvents: any = [];
      res.events?.forEach((event) => {
        newEvents.push(processEvent(event));
      });
      setEvents(newEvents);
      setLoadedPage(true);
    } else if (!loading) {
      history.replace("/");
    }
  }, [loading, data]);

  function eventClicked(event: any) {
    history.push(`/${clientConfig.codename}/event/${event.id}`);
  }

  return (
    <React.Fragment>
      {!loadedPage ? (
        <LoaderContainer />
      ) : (
        <React.Fragment>
          <Navbar clientConfig={clientConfig} />
          <DefaultBodyContainer clientConfig={clientConfig}>
            <StyledCardHeaderClientEvents clientconfig={clientConfig}>
              <h3 style={{ fontSize: 18 }}>Esdeveniments</h3>
            </StyledCardHeaderClientEvents>
            {events.map((event: any, index: number) => (
              <CardItemContainer
                key={index}
                style={{
                  borderBottomLeftRadius: index === events.length - 1 ? 15 : 0,
                  borderBottomRightRadius: index === events.length - 1 ? 15 : 0,
                  borderBottom:
                    index === events.length - 1
                      ? "0.5px solid lightgray"
                      : "none",
                  backgroundColor:
                    clientConfig.bodyBackgroundColor ?? "#FFFFFF",
                  color: clientConfig.bodyColor ?? colors.body.textColor,
                }}
              >
                <CardItemContainer.FirstCol>
                  <div className={styles.firstColContainer}>
                    <div style={{ textTransform: "uppercase" }}>
                      {event.startDate}
                    </div>
                    <Line />
                    {event.startDate !== event.endDate && (
                      <div style={{ textTransform: "uppercase" }}>
                        {event.endDate}
                      </div>
                    )}
                  </div>
                </CardItemContainer.FirstCol>
                <CardItemContainer.MiddleCol>
                  <CardItemContainer.Image src={event.poster} alt="Event" />
                  <div className={styles.eventInfoContainer}>
                    <div className={styles.eventInfoMainContainer}>
                      <div className={styles.eventTextContainer}>
                        <label
                          className={`${styles.eventTextLabel} ${styles.titleLabel}`}
                        >
                          {event.name}
                        </label>
                      </div>
                      <Line />
                      <label
                        className={`${styles.eventTextLabel} ${styles.infoLabel}`}
                      >
                        {event.artistName}
                      </label>
                    </div>
                  </div>
                </CardItemContainer.MiddleCol>
                <CardItemContainer.LastCol>
                  <StyledButtonClientEvents
                    variant="contained"
                    onClick={(e) => eventClicked(event)}
                    clientconfig={clientConfig}
                  >
                    Veure més{" "}
                    <HiOutlineChevronRight
                      style={{ marginLeft: 5 }}
                      size={18}
                      color={
                        clientConfig.mainButtonTextColor ??
                        colors.navbar.activeTextColor
                      }
                    />
                  </StyledButtonClientEvents>
                </CardItemContainer.LastCol>
              </CardItemContainer>
            ))}
            {events.length == 0 && (
              <CardItemContainer
                style={{
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
                  borderBottom: "0.5px solid lightgray",
                  backgroundColor:
                    clientConfig.bodyBackgroundColor ?? "#FFFFFF",
                  height: "auto",
                  minHeight: 60,
                  fontSize: 16,
                  color: "red",
                }}
              >
                <CardItemContainer.FirstCol>
                  No hi ha esdeveniments disponibles
                </CardItemContainer.FirstCol>
              </CardItemContainer>
            )}
          </DefaultBodyContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
