import moment from "moment";
import Moment from "react-moment";

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function stringHasLettersAndNumbers(string: string) {
  const re = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  return re.test(string);
}

export function isNotEmptyString(string: string) {
  return string && string.trim().length > 0 ? true:false;
}

export function positiveEnter(number: any) {
  console.log(number)
  console.log(Number.isInteger(parseInt(number)))
  return (parseInt(number) >= 0 && Number.isInteger(parseInt(number))) || number==="";
}

export function momentIsValid(date:any){
  return date && moment(date,'DD/MM/YYYY HH:mm',true).isValid()
}

export function getValidatorError(item: any, validator: any) {
  if (!validator.validator(item[validator.name])) {
    return validator.error;
  }
  return null
}