import React from "react";
import { Redirect, Route } from "react-router-dom";
import DefaultBodyContainer from "../components/containers/bodyContainer/DefaultBodyContainer";
import DefaultHeader from "../components/navigation/header/DefaultHeader";
import useAuth from "../hooks/auth/useAuth";

export default function SidebarRoute({
  component,
  protectedRoute = true,
  ...rest
}: any) {
  const auth = useAuth();

  const Component = component;
  return (
    <React.Fragment>
      {protectedRoute ? (
        <Route
          {...rest}
          render={({ location }) =>
            auth.user ? (
              <React.Fragment>
                <DefaultHeader />
                <DefaultBodyContainer footer={false} sidebar={true}>
                  <Component />
                </DefaultBodyContainer>
              </React.Fragment>
            ) : (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: location },
                }}
              />
            )
          }
        />
      ) : (
        <Route
          {...rest}
          render={() => (
            <React.Fragment>
              <DefaultHeader />
              <DefaultBodyContainer footer={false} sidebar={true}>
                <Component />
              </DefaultBodyContainer>
            </React.Fragment>
          )}
        />
      )}
    </React.Fragment>
  );
}
