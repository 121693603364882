import {combineReducers} from 'redux'
import AppInitReducer from './appInit/AppInitReducer'
import AuthUserClientReducer from './auth/AuthUserClient'
import AuthUserPermissionsReducer from './auth/AuthUserPermissionsReducer'
import AuthUserReducer from './auth/AuthUserReducer'
import AuthUserRolesReducer from './auth/AuthUserRolesReducer'
import LoadingAuthReducer from './auth/LoadingAuthReducer'
import SidebarFullWidthReducer from './sidebar/SidebarFullWidthReducer'
import SidebarMobileOpened from './sidebar/SidebarMobileOpenedReducer'

export default combineReducers({
    appInit: AppInitReducer,
    sidebarFullWidth: SidebarFullWidthReducer,
    sidebarMobileOpened: SidebarMobileOpened,
    authUser: AuthUserReducer,
    authUserRoles: AuthUserRolesReducer,
    authUserPermissions: AuthUserPermissionsReducer,
    authUserClient: AuthUserClientReducer,
    loadingAuth: LoadingAuthReducer,
})