import moment from "moment";
import { useEffect } from "react";
import { RiAddFill } from "react-icons/ri";
import styles from "./SessionsCreateEventTab.module.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { HiTrash } from "react-icons/hi";
import DefaultInput from "../../../../../../components/parts/inputs/DefaultInput";
import Fab from "@mui/material/Fab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { IndexKind } from "typescript";
export default function SessionsCreateEventTab({
  errors = [],
  sessions = [],
  setSessions,
  tickets = [],
  setTickets,
}: any) {
  useEffect(() => {
    if (sessions.length === 0) {
      addSession();
    }
  },[]);

  function addSession() {
    const newSessions: any = [...sessions];
    const sessionData = sessions.length>0? {...sessions[0]}:{...sessionDefaultData};
    sessionData.name = `Sessió ${newSessions.length + 1}`;
    newSessions.push(sessionData);
    setSessions(newSessions);

    if (tickets.length > 0) {
      const newTickets = [...tickets];
      newTickets.forEach((ticket: any) => {
        ticket.sessionsMax.push({index:(newSessions.length-1),value:""});
      });
      setTickets(newTickets);
    }
  }

  function deleteSession(index: number) {
    if (sessions.length > 1) {
      const newSessions: any = [...sessions];
      newSessions.splice(index, 1);
      setSessions(newSessions);

      if (tickets.length > 0) {
        const newTickets = [...tickets];
        newTickets.forEach((ticket: any) => {
          if (ticket.sessions && ticket.sessions.length - 1 >= index) {
            ticket.sessions.splice(index, 1);
          }
        });
        setTickets(newTickets);
      }
    }
  }

  function changeField(index: any, field: string, value: any) {
    const newSessions: any = [...sessions];
    newSessions[index][field] = value;
    setSessions(newSessions);
  }

  return (
    <div>
      <Typography variant="h5" component="div" style={{ margin: "0 1%" }}>
        Llistat de sessions
      </Typography>
      {sessions.map((session: any, index: number) => (
        <Card className={styles.cardContainer} key={index}>
          <CardContent>
            <div className={styles.inputsContainer}>
              <div className={styles.inputGroup}>
                <div className={styles.infoInput}>
                  <DefaultInput
                    required
                    label="Nom"
                    style={{ width: "100%" }}
                    value={session.name}
                    error={errors[index] && errors[index].name ? true : false}
                    helperText={
                      errors[index] && errors[index].name
                        ? errors[index].name
                        : ""
                    }
                    onChange={(e: any) =>
                      changeField(index, "name", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.dateTimeGroupLeft}>
                  <div className={styles.dateTimeInput}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        ampm={true}
                        inputFormat={'DD/MM/YYYY HH:mm'}
                        renderInput={(props) => (
                          <TextField
                            error={
                              errors[index] && errors[index].inscriptionStart
                                ? true
                                : false
                            }
                            helperText={
                              errors[index] && errors[index].inscriptionStart
                                ? errors[index].inscriptionStart
                                : ""
                            }
                            required
                            {...props}
                            style={{ width: "100%" }}
                          />
                        )}
                        label="Inici inscripcions"
                        value={session.inscriptionStart}
                        onChange={(newValue) => {
                          changeField(index, "inscriptionStart", newValue);
                        }}
                        className={styles.input}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={styles.dateTimeInput}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        ampm={true}
                        inputFormat={'DD/MM/YYYY HH:mm'}
                        renderInput={(props) => (
                          <TextField
                            error={
                              errors[index] && errors[index].inscriptionEnd
                                ? true
                                : false
                            }
                            helperText={
                              errors[index] && errors[index].inscriptionEnd
                                ? errors[index].inscriptionEnd
                                : ""
                            }
                            required
                            {...props}
                            style={{ width: "100%" }}
                          />
                        )}
                        label="Final inscripcions"
                        value={session.inscriptionEnd}
                        onChange={(newValue) => {
                          changeField(index, "inscriptionEnd", newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.dateTimeGroupRight}>
                  <div className={styles.dateTimeInput}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        inputFormat={'DD/MM/YYYY HH:mm'}
                        ampm={true}
                        renderInput={(props) => (
                          <TextField
                            error={
                              errors[index] && errors[index].start
                                ? true
                                : false
                            }
                            helperText={
                              errors[index] && errors[index].start
                                ? errors[index].start
                                : ""
                            }
                            required
                            {...props}
                            style={{ width: "100%" }}
                          />
                        )}
                        label="Inici Sessió"
                        value={session.start}
                        onChange={(newValue) => {
                          changeField(index, "start", newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={styles.dateTimeInput}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        inputFormat={'DD/MM/YYYY HH:mm'}
                        ampm={true}
                        renderInput={(props) => (
                          <TextField
                            error={
                              errors[index] && errors[index].end
                                ? true
                                : false
                            }
                            helperText={
                              errors[index] && errors[index].end
                                ? errors[index].end
                                : ""
                            }
                            required
                            {...props}
                            style={{ width: "100%" }}
                          />
                        )}
                        label="Final Sessió"
                        value={session.end}
                        onChange={(newValue) => {
                          changeField(index, "end", newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              <div className={styles.inputGroup}>
                <div className={styles.infoInput}>
                  <DefaultInput
                    type="number"
                    label="Capacitat"
                    error={
                      errors[index] && errors[index].capacity ? true : false
                    }
                    helperText={
                      errors[index] && errors[index].capacity
                        ? errors[index].capacity
                        : ""
                    }
                    style={{ width: "100%" }}
                    value={session.capacity}
                    onChange={(e: any) =>
                      changeField(index, "capacity", e.target.value)
                    }
                  />
                </div>
                <div className={styles.infoInput}>
                  <DefaultInput
                    required
                    error={
                      errors[index] && errors[index].country ? true : false
                    }
                    helperText={
                      errors[index] && errors[index].country
                        ? errors[index].country
                        : ""
                    }
                    label="País"
                    style={{ width: "100%" }}
                    value={session.country}
                    onChange={(e: any) =>
                      changeField(index, "country", e.target.value)
                    }
                  />
                </div>
                <div className={styles.infoInput}>
                  <DefaultInput
                    required
                    error={
                      errors[index] && errors[index].state ? true : false
                    }
                    helperText={
                      errors[index] && errors[index].state
                        ? errors[index].state
                        : ""
                    }
                    label="Municipi"
                    style={{ width: "100%" }}
                    value={session.state}
                    onChange={(e: any) =>
                      changeField(index, "state", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.infoInput}>
                  <DefaultInput
                    required
                    error={
                      errors[index] && errors[index].city ? true : false
                    }
                    helperText={
                      errors[index] && errors[index].city
                        ? errors[index].city
                        : ""
                    }
                    label="Ciutat"
                    style={{ width: "100%" }}
                    value={session.city}
                    onChange={(e: any) =>
                      changeField(index, "city", e.target.value)
                    }
                  />
                </div>
                <div className={styles.infoInput}>
                  <DefaultInput
                    required
                    label="Codi postal"
                    error={errors[index] && errors[index].postalCode ? true : false}
                    helperText={
                      errors[index] && errors[index].postalCode
                        ? errors[index].postalCode
                        : ""
                    }
                    style={{ width: "100%" }}
                    value={session.postalCode}
                    onChange={(e: any) =>
                      changeField(index, "postalCode", e.target.value)
                    }
                  />
                </div>
                <div className={styles.infoInput}>
                  <DefaultInput
                    required
                    error={errors[index] && errors[index].place ? true : false}
                    helperText={
                      errors[index] && errors[index].place
                        ? errors[index].place
                        : ""
                    }
                    label="Carrer / Lloc"
                    style={{ width: "100%" }}
                    value={session.place}
                    onChange={(e: any) =>
                      changeField(index, "place", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </CardContent>
          <CardActions className={styles.actionsContainer}>
            <IconButton title="Eliminar" onClick={(e) => deleteSession(index)}>
              <HiTrash size={24} color={"#e22718"} />
            </IconButton>
          </CardActions>
        </Card>
      ))}

      <div
        style={{ display: "flex", marginTop: 20, justifyContent: "flex-end" }}
      >
        <Fab
          color="primary"
          className={styles.createButton}
          onClick={() => addSession()}
          aria-label="edit"
        >
          <RiAddFill size={29} />
        </Fab>
      </div>
    </div>
  );
}

const sessionDefaultData = {
  name: "",
  inscriptionStart: moment(),
  inscriptionEnd: moment().add(15, "days"),
  start: moment().add(15, "days"),
  end: moment().add(15, "days").add(2, "hours"),
  country: "",
  state: "",
  city: "",
  postalCode: "",
  capacity: 0,
  place: ""
};
