import Chip from '@mui/material/Chip'
import { useHistory } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import useIsMobileSize from '../../../hooks/windowSize/useIsMobileSize';

export default function HeaderBreadcrumb({className = "", options = [] , ...rest}){
    const history = useHistory()
    const isMobileSize = useIsMobileSize()
    return (
        <Breadcrumbs style={{ marginTop: isMobileSize ? 15 : 0 }}  {...rest} aria-label="breadcrumb">
            {options.map((option:any,index) => (
                <Chip 
                    key={index}
                    onClick={() => history.push(option.link)} label={option.name}
                    icon={option.icon}
                    size="small"
                    style={{ backgroundColor: index===(options.length-1)? '#cecece':'' }}
                />
            ))}
        </Breadcrumbs>
    )
}