import Button from "@mui/material/Button";
import styled from "styled-components";
import colors from "../../../constants/colors";

export const StyledFinishButtonInscriptionCheckout = styled(Button)<{clientconfig:any}>`
border-radius: 9px;
height: 43px;
width: 95%;
max-width: 500px;
font-size: 15px;
margin: 10px auto;
background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
colors.navbar.backgroundColor};
color: ${params => params.clientconfig.mainButtonTextColor ?? colors.navbar.activeTextColor};

:active{
  opacity: 0.8;
}

:hover{
  background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
colors.navbar.backgroundColor};
}

`