export default function LoadingAuthReducer(
    state: any = false,
    action: any
  ) {
    switch (action.type) {
      case "SET_LOADING_AUTH" :
        return true;
      case "REMOVE_LOADING_AUTH":
        return false;
      default:
        return state;
    }
  }
  
  LoadingAuthReducer.actions = {
    loading: { type: "SET_LOADING_AUTH" },
    setLoaded: { type: "REMOVE_LOADING_AUTH"},
  };
  