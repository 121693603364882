import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";

export default function DefaultHeader(){
    return (
        <div>
            <Sidebar />
            <Navbar sidebar={true}/>
        </div>
    )
}