import Button from "@mui/material/Button";
import styled from "styled-components";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import colors from "../../../constants/colors";

export const StyledHeaderEventInfo = styled.div<{clientconfig:any,event:any}>`
    background-color: ${params => params.clientconfig.backgroundColor ??
    colors.navbar.backgroundColor};
    color: ${params => params.clientconfig.bodyTextColor ?? colors.navbar.activeTextColor};
    background: ${params => params.event.poster ? "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(" + params.event.poster + ")" : ""};
    filter: blur(15px);
    -webkit-filter: blur(15px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `;

  export const StyledButtonEventInfo = styled(Button)<{clientconfig:any}>`
    height: 43px;
    width: 100%;
    background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
    colors.navbar.backgroundColor};
    color: ${params => params.clientconfig.mainButtonTextColor ?? colors.navbar.activeTextColor};
    word-break: break-word;
    :hover {
      opacity: 0.8;
      background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
      colors.navbar.backgroundColor};
    }
  `;

  export const StyledCardHeaderEventInfo = styled(CardItemContainer)`
    background-color: ${params => params.clientconfig.bodyBackgroundColor ?? "#FFFFFF"};
    color: ${params => params.clientconfig.bodyColor ?? colors.body.textColor};
  `;
