import { CLIENT_BACKGROUND_IMAGE_BASE_ROUTE, CLIENT_LOGO_IMAGE_BASE_ROUTE } from "../hooks/api/useApi";

export function clientToClientConfig(clientData: any = {}) {
  return {
    id: clientData.id,
    name: clientData.name,
    codename: clientData.codename,
    logo: clientData.logo ? `${CLIENT_LOGO_IMAGE_BASE_ROUTE}${clientData.logo.filename}` : "",
    navbarBackgroundColor: clientData.navbarBackgroundColor,
    navbarTextColor: clientData.navbarTextColor,
    bodyTextColor: clientData.bodyTextColor,
    backgroundImage: clientData.backgroundImage
      ? `${CLIENT_BACKGROUND_IMAGE_BASE_ROUTE}${clientData.backgroundImage.filename}`
      : "",
    backgroundColor: clientData.backgroundColor,
    bodyBackgroundColor: clientData.bodyBackgroundColor,
    mainButtonBackgroundColor: clientData.mainButtonBackgroundColor,
    mainButtonTextColor: clientData.mainButtonTextColor,
  };
}
