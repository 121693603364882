import { Modal } from "@mui/material";
import React, { useState } from "react";
import SignInModal from "./SignInModal/SignInModal";
import SignUpModal from "./SignUpModal/SignUpModal";

export default function AuthModal({setAuthModalOpened,clientConfig = {},opened = true,...rest}:any){
    const [showType,setShowType] = useState(0)

    return (
        <Modal open={opened} {...rest} style={{ overflow:'auto' }}>
            <React.Fragment>
            {showType===0?
                <SignInModal setAuthModalOpened={setAuthModalOpened} setShowType={setShowType} clientConfig={clientConfig}/>:
                <SignUpModal setAuthModalOpened={setAuthModalOpened} setShowType={setShowType} clientConfig={clientConfig}/>
            }
            </React.Fragment>
        </Modal>


    )

}