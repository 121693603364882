import { useState } from "react";
import TabsContainer from "../../../components/containers/tabsContainer/TabsContainer";
import PageBody from "../../../components/parts/pageBody/PageBody";
import PageTitle from "../../../components/parts/pageTitle/PageTitle";
import styles from "./MyTickets.module.css";
import { PastEvents } from "./pastEvents/PastEvents";
import UpcomingEvents from "./upcomingEvents/UpcomingEvents";
export default function MyTickets() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={styles.container}>
      <PageTitle>
        <PageTitle.Title>Les meves entrades</PageTitle.Title>
      </PageTitle>
      <PageBody>
      <TabsContainer>
          <TabsContainer.TabsContainer
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabsContainer.Tabs
              value={activeTab}
              onChange={(event: any, value: number) => setActiveTab(value)}
              centered
            >
              {tabs.map((tab: any, index: number) => (
                <TabsContainer.Tab label={tab.label} key={index} />
              ))}
            </TabsContainer.Tabs>
          </TabsContainer.TabsContainer>
          {tabs.map((tab: any, index: number) => (
            <TabsContainer.TabPanel value={activeTab} index={index} key={index}>
              {<tab.component {...tab.params} />}
            </TabsContainer.TabPanel>
          ))}
        </TabsContainer>

      </PageBody>
    </div>
  );
}

function Test({test = "11"}){
    return (
        <div>
            {test}
        </div>
    )
}

const tabs: any = [
    {
      label: "Esdeveniments pendents",
      icon: null,
      component: UpcomingEvents,
      params:{}
    },
    {
      label: "Esdeveniments passats",
      icon: null,
      component: PastEvents,
      params:{}
    },
  ];