import Button from "@material-ui/core/Button";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import colors from "../../../constants/colors";
import sizes from "../../../constants/sizes";


export const StyledHeaderBackgroundEventTicket = styled.div<{clientconfig:any,event:any}>`
    position: fixed;
    top: ${sizes.navbar.height +"px"};
    width: 100%;
    height: 200px;
    background-color: ${params =>  params.clientconfig.backgroundColor ??
    colors.navbar.backgroundColor};
    color: "#FFFFFF";
    background-image: ${params => params.event.poster
      ? "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(" +
        params.event.poster +
        ")"
      : "none"};
    filter: blur(10px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index : 1;
    user-select: none;


    @media (max-width: 990px) {
      height: 250px;
    }
  `;

  export const StyledHeaderContainerEventTicket = styled.div`
    position: fixed;
    top: ${sizes.navbar.height + "px"};
    height: 203px;
    width: 100%;
    display: flex;
    color: #ffffff;
    padding: 0px 2%;
    align-items: center;
    z-index : 2;

    @media (max-width: 990px) {
      justify-content: center;
      height: 253px;
    }
  `;

  export const StyledCardHeaderEventTicket = styled(CardItemContainer)<{clientconfig:any}>`
    background-color: ${params => params.clientconfig.bodyBackgroundColor ?? "#FFFFFF"};
    color: ${params => params.clientconfig.bodyColor ?? colors.body.textColor};
  `;

  export const StyledIconButtonEventTicket = styled(IconButton)`
    padding:6px;

    :active{
      opacity: 0.6;
    }
  `

  export const StyledBuyButtonEventTicket = styled(Button)<{clientconfig:any}>`
    border-radius: 9px;
    height: 43px;
    width: 95%;
    max-width: 500px;
    font-size: 15px;
    margin: 10px auto;
    background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
    colors.navbar.backgroundColor};
    color: ${params => params.clientconfig.mainButtonTextColor ?? colors.navbar.activeTextColor};

    :active{
      opacity: 0.8;
    }

    :hover{
      background-color: ${params => params.clientconfig.mainButtonBackgroundColor ??
    colors.navbar.backgroundColor};
    }

  `
