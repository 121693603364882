import Fab from "@material-ui/core/Fab";
import React, { useState } from "react";
import TabsContainer from "../../../../components/containers/tabsContainer/TabsContainer";
import HeaderBreadcrumb from "../../../../components/navigation/HeaderBreadcrumb/HeaderBreadcrumb";
import PageBody from "../../../../components/parts/pageBody/PageBody";
import PageTitle from "../../../../components/parts/pageTitle/PageTitle";
import styles from "./CreateEvent.module.css";
import InfoCreateEventTab from "./tabs/info/InfoCreateEventTab";
import SessionsCreateEventTab from "./tabs/sessions/SessionsCreateEventTab";
import TicketsCreateEventTab from "./tabs/tickets/TicketsCreateEventTab";
import { GrSave } from "react-icons/gr";
import { IoSaveOutline } from "react-icons/io5";
import {
  getValidatorError,
  isNotEmptyString,
  momentIsValid,
  positiveEnter,
} from "../../../../utils/validators";
import Alert from "@material-ui/core/Alert";
import AlertTitle from "@material-ui/core/AlertTitle";
import { useAddEventMutationMutation } from "../../../../generated/graphql";
import useAuth from "../../../../hooks/auth/useAuth";
import showToast from "../../../../utils/toastUtils";
import { toast } from "material-react-toastify";
import { useHistory } from "react-router-dom";

export default function CreateEvent() {
  const auth = useAuth()
  const [loading,setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors]: any = useState({
    info: {},
    sessions: {},
    tickets: {},
  });
  const [infoError, setInfoError] = useState(false);
  const [sessionsError, setSessionsError] = useState(false);
  const [ticketsError, setTicketsError] = useState(false);
  const [info, setInfo]: any = useState({
    active: true,
    eventName: "",
    description: "",
    artistName: "",
    poster: null,
    posterUrl: "",
  });
  const [sessions, setSessions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [poster, setPoster] = useState(null);
  const [addEvent] = useAddEventMutationMutation()
  const history = useHistory()
  
  const tabs: any = [
    {
      label: "Informació",
      icon: null,
      component: InfoCreateEventTab,
      params: {
        errors: errors.info,
        info: info,
        setInfo: setInfo,
        poster,
        setPoster,
      },
    },
    {
      label: "Sessions",
      icon: null,
      component: SessionsCreateEventTab,
      params: {
        errors: errors.sessions,
        sessions: sessions,
        setSessions: setSessions,
        tickets: tickets,
        setTickets: setTickets,
      },
    },
    {
      label: "Tiquets",
      icon: null,
      component: TicketsCreateEventTab,
      params: {
        errors: errors.tickets,
        tickets: tickets,
        setTickets: setTickets,
        sessions: sessions,
      },
    },
  ];

  async function saveClick() {
    if(!loading){
      const errorsFound = checkErrors();
      if (!errorsFound.errorFound) {
        setLoading(true)
        console.log("poster",poster)
        const addEventParams = {
          clientId: auth.client? auth.client.id:null,
          eventName: info.eventName,
          active: info.active,
          description: info.description,
          artistName: info.artistName,
          poster,
          sessions,
          tickets
        }
        try {
          const {data} = await addEvent({
            variables: {addEventParams}
          })
          history.replace('/admin/events')
          showToast("success",{message: "Esdeveniment creat correctament"})
          setLoading(false)
        } catch (error) {
          console.log("error",error)
          showToast("error",{message: "Hi ha hagut un error! Torna-ho a intentar!"})
          setLoading(false) 
        }
      } else {
        //mostrar
        showToast("error",{message: "Revisa els errors abans de continuar!"}) 
        setLoading(false)
      }
    }
  }

  function checkErrors() {
    const infoErrors = checkInfoErrors();
    const sessionsErrors = checkSessionErrors();
    const ticketsErrors = checkTicketsErrors();
    const errorsFound = {
      info: infoErrors.errors,
      sessions: sessionsErrors.errors,
      tickets: ticketsErrors.errors,
    };
    setErrors(errorsFound);
    setInfoError(infoErrors.errorFound);
    setSessionsError(sessionsErrors.errorFound);
    setTicketsError(ticketsErrors.errorFound);
    const errorExist =
      infoErrors.errorFound ||
      sessionsErrors.errorFound ||
      ticketsErrors.errorFound;
    return {
      errors: errorsFound,
      errorFound: errorExist,
    };
  }

  function checkInfoErrors() {
    const infoErrors: any = {};
    let error = false;
    infoValidator.forEach((validator) => {
      const key = validator.name;
      infoErrors[key] = getValidatorError(info, validator);
      if (!error && infoErrors[key]) {
        error = true;
      }
    });

    infoErrors.poster = poster ? null : "Selecciona una imatge pel poster";
    return { errors: infoErrors, errorFound: error || poster === null };
  }

  function checkSessionErrors() {
    const sessionsErrors: any = [];
    let error = false;
    sessions.forEach((session) => {
      const sessionErrors: any = {};
      sessionsValidators.forEach((validator) => {
        const key = validator.name;
        sessionErrors[key] = getValidatorError(session, validator);
        if (!error && sessionErrors[key]) {
          error = true;
        }
      });
      sessionsErrors.push(sessionErrors);
    });
    return {
      errors: sessionsErrors,
      errorFound: error || sessions.length === 0,
    };
  }

  function checkTicketsErrors() {
    const ticketsErrors: any = [];
    let error = false;
    tickets.forEach((ticket) => {
      const ticketErrors: any = {};
      ticketsValidators.forEach((validator) => {
        const key = validator.name;
        ticketErrors[key] = getValidatorError(ticket, validator);
        if (!error && ticketErrors[key]) {
          error = true;
        }
      });
      ticketsErrors.push(ticketErrors);
    });
    return { errors: ticketsErrors, errorFound: error || tickets.length === 0 };
  }

  return (
    <React.Fragment>
      <PageTitle>
        <PageTitle.Title>Crear Esdeveniment</PageTitle.Title>
        <HeaderBreadcrumb options={breadcumOptions} />
      </PageTitle>
      <PageBody>
        <TabsContainer>
          <TabsContainer.TabsContainer
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabsContainer.Tabs
              value={activeTab}
              onChange={(event: any, value: number) => setActiveTab(value)}
              centered
            >
              {tabs.map((tab: any, index: number) => (
                <TabsContainer.Tab label={tab.label} key={index} />
              ))}
            </TabsContainer.Tabs>
          </TabsContainer.TabsContainer>
          {(infoError || sessionsError || ticketsError) && (
            <Alert severity="error" style={{ marginTop: 15, borderRadius: 13, marginRight: '1%', marginLeft:'1%' }}>
              <AlertTitle style={{ marginBottom: 15 }}>
                Revisa els errors abans de continuar
              </AlertTitle>
              {infoError && (
                <div style={{ margin: "3px 0px" }}>
                  - Revisa els errors de l'informació general
                </div>
              )}
              {sessionsError && (
                <div style={{ margin: "3px 0px" }}>
                  - Revisa els errors de les sessions
                </div>
              )}
              {ticketsError && (
                <div style={{ margin: "3px 0px" }}>
                  - Revisa els errors dels tiquets
                </div>
              )}
            </Alert>
          )}
          {tabs.map((tab: any, index: number) => (
            <TabsContainer.TabPanel value={activeTab} index={index} key={index}>
              {<tab.component {...tab.params} />}
            </TabsContainer.TabPanel>
          ))}
        </TabsContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "1%",
            alignItems: "center",
          }}
        >
          <Fab
            variant="extended"
            className={styles.saveButton}
            onClick={() => saveClick()}
            aria-label="edit"
            disabled={loading}
          >
            <IoSaveOutline
              size={20}
              color="white"
              style={{ marginRight: 10 }}
            />
            Guardar canvis
          </Fab>
        </div>
      </PageBody>
    </React.Fragment>
  );
}

const breadcumOptions: any = [
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Llistat d'esdeveniments",
    link: "/admin/events",
  },
  {
    name: "Crear esdeveniment",
    link: "/admin/events/create",
  },
];

const infoValidator = [
  {
    name: "eventName",
    validator: isNotEmptyString,
    error: "Introdueix el nom de l'esdeveniment",
  },
  {
    name: "artistName",
    validator: isNotEmptyString,
    error: "Introdueix el nom de l'autor",
  },
  {
    name: "description",
    validator: isNotEmptyString,
    error: "Introdueix una descipció",
  },
];

const sessionsValidators = [
  {
    name: "name",
    validator: isNotEmptyString,
    error: "Introdueix el nom de la sessió",
  },
  {
    name: "inscriptionStart",
    validator: momentIsValid,
    error: "Introdueix la  d'inici d'inscripcions",
  },
  {
    name: "inscriptionEnd",
    validator: momentIsValid,
    error: "Introdueix la de fi d'inscripcions",
  },
  {
    name: "start",
    validator: momentIsValid,
    error: "Introdueix la d'inici de la sessió",
  },
  {
    name: "end",
    validator: momentIsValid,
    error: "Introdueix la de fi de la sessió",
  },
  {
    name: "capacity",
    validator: positiveEnter,
    error:
      "La capacitat ha de ser enter positiu. Deixa-ho en blanc o 0 per il·limitat",
  },
  {
    name: "postalCode",
    validator: isNotEmptyString,
    error: "Introdueix el codi postal",
  },
  {
    name: "country",
    validator: isNotEmptyString,
    error: "Introdueix el païs",
  },
  {
    name: "state",
    validator: isNotEmptyString,
    error: "Introdueix el municipi",
  },
  {
    name: "city",
    validator: isNotEmptyString,
    error: "Introdueix la ciutat",
  },
  {
    name: "place",
    validator: isNotEmptyString,
    error: "Introdueix el carrer o lloc",
  }
];

const ticketsValidators = [
  {
    name: "name",
    validator: isNotEmptyString,
    error: "Introdueix el nom del ticket",
  },
  {
    name: "description",
    validator: isNotEmptyString,
    error: "Introdueix la descripció del ticket",
  },
];
