// import "bootstrap/dist/js/bootstrap.bundle";
import "material-react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18next";
import App from "./App";
import { Provider } from "react-redux";
import { createStore } from "redux";
import AppReducers from "./reducers/AppReducers";
import { StyledEngineProvider } from "@mui/material/styles";
import { ToastContainer } from "material-react-toastify";
import { CookiesProvider } from "react-cookie";
import LoaderContainer from "./components/containers/loaderContainer/LoaderContainer";

const store = createStore(AppReducers);

ReactDOM.render(
  <React.StrictMode>
      <ToastContainer />
      <StyledEngineProvider injectFirst>
      <Suspense fallback={<LoaderContainer />}>
        <CookiesProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </CookiesProvider>
        </Suspense>
      </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
