import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormEvent, useState } from "react";
import { GrClose } from "react-icons/gr";
import useApi from "../../../../hooks/api/useApi";
import useAuth from "../../../../hooks/auth/useAuth";
import showToast from "../../../../utils/toastUtils";
import { stringHasLettersAndNumbers, validateEmail } from "../../../../utils/validators";
import CardContainer from "../../../containers/cardContainer/CardContainer";
import styles from './SignInModal.module.css'

export default function SignInModal({setAuthModalOpened,setShowType,clientConfig = {}}:any){
    const auth = useAuth();
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [errors,setErrors] :any= useState([])
    const [showPassword,setShowPassword] = useState(false)
    const [loading,setLoading] = useState(false)
    const {apiPost,apiRoutes} = useApi()
    const [remember,setRemember] = useState(true)
    
  
    function checkErrors(){
      const newErrors = []
      if (!email || email.trim().length === 0 || !validateEmail(email)) {
        newErrors.push("email");
      }
      
      if (
        !password ||
        password.trim().length < 5 ||
        password.trim().length > 60 ||
        !stringHasLettersAndNumbers(password)
      ) {
        newErrors.push("password");
      }
  
      setErrors(newErrors)
      return newErrors
    }
  
    async function siginin(e:FormEvent) {
     e.preventDefault()
     const new_errors = await checkErrors()
     if(new_errors.length===0 && !loading){
       setLoading(true)
       const params = {
        email,
        password
      }
      const res = await apiPost(apiRoutes.signin,params)
      if(res.success && res.data.user){
        showToast("success",{message: "Autenticació realitzada correctament!"})
        auth.signin(res.data.user, () =>{
          setAuthModalOpened(false)
        },remember)
      } else{
        console.log("error")
        showToast("error",{message: "Ja existeix un usuari amb aquest correu electrònic!"})
        setErrors([...errors,'password'])
        setLoading(false)
      }
     }
    }

    function signUpClicked(){
        if(!loading){
            setShowType(1)
        }
    }
  
    return (
      <CardContainer className={styles.signInContainer}>
          <div className={styles.closeIconContainer} onClick={(e:any) => setAuthModalOpened(false)}>
              <GrClose size={16}/>
          </div>
        <CardContainer.Header>
          <CardContainer.HeaderTitle>Iniciar Sessió</CardContainer.HeaderTitle>
          <CardContainer.Body>
            <form>
              <CardContainer.InputGroup>
              <CardContainer.Input
                  type="email"
                  value={email}
                  error={errors.includes("email")}
                  label="Correu electrònic"
                  onChange={(e: any) => setEmail(e.target.value)}
                  helperText={errors.includes("email")? "Introdueix un email vàlid i no existent!":""}
                />
              </CardContainer.InputGroup>
              <CardContainer.InputGroup>
              <CardContainer.PasswordInput
                  error={errors.includes("password")}
                  label="Contrasenya"
                  value={password}
                  showPassword={showPassword}
                  showPasswordFunction={() => setShowPassword(!showPassword)}
                  onChange={(e: any) => setPassword(e.target.value)}
                  helperText={errors.includes("password")? "La contrasenya no és vàlida!":"" }
                />
              </CardContainer.InputGroup>
              <div className={styles.linkForgetContainer}>
              <FormControlLabel control={<Checkbox checked={remember} onClick={() => setRemember(!remember)} />} label="Recorda'm"/>
                <CardContainer.Link to={"/signup"} className={styles.signinLink}>
                  Has oblidat la contrasenya?
                </CardContainer.Link>
              </div>
              <CardContainer.Button onClick={(e:FormEvent) => siginin(e)} disabled={loading}>Iniciar sessió</CardContainer.Button>
              <div onClick={(e:any) => signUpClicked()} className={styles.signinLinkBottom} >
                Crear un compte
              </div>
            </form>
          </CardContainer.Body>
        </CardContainer.Header>
      </CardContainer>
    );


}