import moment from "moment";
import { EVENT_IMAGE_BASE_ROUTE } from "../hooks/api/useApi";

export function processEvent(event: any) {
  moment.locale('ca')
  const newEvent = {
    id: event.id,
    name: event.name,
    poster: event.poster
      ? `${EVENT_IMAGE_BASE_ROUTE}${event.poster.filename}`
      : "",
    artistName: event.artistName,
    description: event.description ?? '',
    startDate: "",
    endDate: "",
  };

  if (event.sessions) {
    event.sessions.forEach((session: any) => {
      const startDate = moment(session.start);
      if (
        !newEvent.startDate ||
        startDate.isBefore(moment(newEvent.startDate))
      ) {
        newEvent.startDate = startDate.format("DD MMM");
      }

      const endDate = moment(session.end);
      if (!newEvent.endDate || endDate.isAfter(moment(newEvent.endDate))) {
        newEvent.endDate = endDate.format("DD MMM");
      }
    });
  }
  return newEvent
}
