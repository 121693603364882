import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Add/edit params */
export type AddEditEventInput = {
  active: Scalars['Boolean'];
  artistName: Scalars['String'];
  clientId: Scalars['Float'];
  description: Scalars['String'];
  eventId?: Maybe<Scalars['Float']>;
  eventName: Scalars['String'];
  poster: Scalars['Upload'];
  sessions: Array<Scalars['JSON']>;
  tickets: Scalars['JSON'];
};

export type Artist = {
  __typename?: 'Artist';
  description: Scalars['String'];
  events: Array<Event>;
  id: Scalars['Float'];
  image: Document;
  name: Scalars['String'];
};

/** block inscriptions */
export type BlockInscriptionsInput = {
  clientId: Scalars['Float'];
  eventId: Scalars['Float'];
  sessionId: Scalars['Float'];
  ticketsIdWithQuantity: Array<Scalars['JSON']>;
};

/** block inscriptions return */
export type BlockInscriptionsReturn = {
  __typename?: 'BlockInscriptionsReturn';
  client?: Maybe<Client>;
  event?: Maybe<Event>;
  inscriptionsBlocked?: Maybe<Array<InscriptionBlocked>>;
  session?: Maybe<Session>;
};

export type Client = {
  __typename?: 'Client';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Document>;
  bodyBackgroundColor?: Maybe<Scalars['String']>;
  bodyTextColor?: Maybe<Scalars['String']>;
  codename: Scalars['String'];
  events?: Maybe<Array<Event>>;
  id: Scalars['Float'];
  logo?: Maybe<Document>;
  mainButtonBackgroundColor?: Maybe<Scalars['String']>;
  mainButtonTextColor?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  navbarBackgroundColor?: Maybe<Scalars['String']>;
  navbarTextColor?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
};

export type Document = {
  __typename?: 'Document';
  extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  active: Scalars['Boolean'];
  artist?: Maybe<Artist>;
  artistName: Scalars['String'];
  client?: Maybe<Client>;
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  poster?: Maybe<Document>;
  sessions?: Maybe<Array<Session>>;
  tickets?: Maybe<Array<Ticket>>;
};

export type Inscription = {
  __typename?: 'Inscription';
  event: Event;
  groupId: Scalars['Float'];
  id: Scalars['Float'];
  inscriptionReadLogs: Array<InscriptionReadLog>;
  session: Session;
  ticket: Ticket;
  ticketSession: TicketSession;
  token: Scalars['String'];
  user: User;
};

export type InscriptionBlocked = {
  __typename?: 'InscriptionBlocked';
  blockedDate: Scalars['DateTime'];
  deleteDate: Scalars['DateTime'];
  id: Scalars['Float'];
  session: Session;
  ticket: Ticket;
  ticketSession: TicketSession;
  user: User;
};

export type InscriptionQueue = {
  __typename?: 'InscriptionQueue';
  id: Scalars['Float'];
  ticketSession: TicketSession;
};

export type InscriptionReadLog = {
  __typename?: 'InscriptionReadLog';
  id: Scalars['Float'];
  inscription: Inscription;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEvent: Event;
  blockInscriptions: Array<InscriptionBlocked>;
  createInscriptionsFromBlockedInscriptions: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  readInscription: ReadInscriptionReturn;
  validateBlockedInscriptions: BlockInscriptionsReturn;
};


export type MutationAddEventArgs = {
  params: AddEditEventInput;
};


export type MutationBlockInscriptionsArgs = {
  params: BlockInscriptionsInput;
};


export type MutationCreateInscriptionsFromBlockedInscriptionsArgs = {
  blockedInscriptionsIds: Array<Scalars['Float']>;
  clientCodename: Scalars['String'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['Float'];
};


export type MutationReadInscriptionArgs = {
  eventId: Scalars['Float'];
  inscriptionToken: Scalars['String'];
  sessionId?: Maybe<Scalars['Float']>;
};


export type MutationValidateBlockedInscriptionsArgs = {
  blockedInscriptionsIds: Array<Scalars['Float']>;
  clientCodename: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getEventSelected: Client;
  getEventsAdmin: Array<Event>;
  getEventsClient: Client;
  getEventsClientsForRead: Array<Event>;
  getMyPastTickets: Array<Inscription>;
  getMyUpcomingTickets: Array<Inscription>;
  getSessionTickets: Client;
  test: Scalars['Boolean'];
};


export type QueryGetEventSelectedArgs = {
  clientCodename: Scalars['String'];
  eventId: Scalars['Float'];
};


export type QueryGetEventsAdminArgs = {
  clientId: Scalars['Float'];
};


export type QueryGetEventsClientArgs = {
  clientCodename: Scalars['String'];
};


export type QueryGetEventsClientsForReadArgs = {
  clientCodenames: Array<Scalars['String']>;
};


export type QueryGetSessionTicketsArgs = {
  clientCodename: Scalars['String'];
  eventId: Scalars['Float'];
  sessionId: Scalars['Float'];
};

export type Role = {
  __typename?: 'Role';
  codename: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  permissions: Array<RolePermission>;
  users: Array<User>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  codename: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  roles: Array<Role>;
};

export type Session = {
  __typename?: 'Session';
  capacity: Scalars['Float'];
  city: Scalars['String'];
  country: Scalars['String'];
  end: Scalars['DateTime'];
  event: Event;
  id: Scalars['Float'];
  inscriptionEnd: Scalars['DateTime'];
  inscriptionStart: Scalars['DateTime'];
  name: Scalars['String'];
  place: Scalars['String'];
  postalCode: Scalars['String'];
  soldTickets?: Maybe<Scalars['Float']>;
  start: Scalars['DateTime'];
  state: Scalars['String'];
  ticketsSession: Array<TicketSession>;
};

export type Ticket = {
  __typename?: 'Ticket';
  description: Scalars['String'];
  event: Event;
  id: Scalars['Float'];
  inscriptions: Array<Inscription>;
  name: Scalars['String'];
  ticketsSession: Array<TicketSession>;
};

export type TicketSession = {
  __typename?: 'TicketSession';
  id: Scalars['Float'];
  inscriptions: Array<Inscription>;
  inscriptionsBlocked: Array<InscriptionBlocked>;
  inscriptionsQueue: Array<InscriptionQueue>;
  maxInscriptions: Scalars['Float'];
  price: Scalars['Float'];
  session: Session;
  soldSessionTicket?: Maybe<Scalars['Int']>;
  ticket: Ticket;
  ticketOffice: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  client: Client;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  inscriptions: Array<Inscription>;
  inscriptionsBlocked: Array<InscriptionBlocked>;
  lastNames: Scalars['String'];
  roles: Array<Role>;
};

/** read inscriptions return */
export type ReadInscriptionReturn = {
  __typename?: 'readInscriptionReturn';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PublicClientEventsQueryVariables = Exact<{
  clientCodename: Scalars['String'];
}>;


export type PublicClientEventsQuery = { __typename?: 'Query', getEventsClient: { __typename?: 'Client', id: number, name: string, navbarBackgroundColor?: Maybe<string>, navbarTextColor?: Maybe<string>, bodyBackgroundColor?: Maybe<string>, bodyTextColor?: Maybe<string>, backgroundColor?: Maybe<string>, mainButtonBackgroundColor?: Maybe<string>, mainButtonTextColor?: Maybe<string>, events?: Maybe<Array<{ __typename?: 'Event', id: number, name: string, artistName: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, sessions?: Maybe<Array<{ __typename?: 'Session', end: any, start: any }>> }>>, backgroundImage?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, logo?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }> } };

export type PublicEventInfoQueryVariables = Exact<{
  eventId: Scalars['Float'];
  clientCodename: Scalars['String'];
}>;


export type PublicEventInfoQuery = { __typename?: 'Query', getEventSelected: { __typename?: 'Client', id: number, name: string, codename: string, navbarBackgroundColor?: Maybe<string>, navbarTextColor?: Maybe<string>, bodyBackgroundColor?: Maybe<string>, bodyTextColor?: Maybe<string>, backgroundColor?: Maybe<string>, mainButtonBackgroundColor?: Maybe<string>, mainButtonTextColor?: Maybe<string>, backgroundImage?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, logo?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, events?: Maybe<Array<{ __typename?: 'Event', id: number, name: string, artistName: string, description: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, sessions?: Maybe<Array<{ __typename?: 'Session', capacity: number, place: string, state: string, postalCode: string, city: string, country: string, end: any, start: any, inscriptionEnd: any, inscriptionStart: any, name: string, id: number, soldTickets?: Maybe<number> }>> }>> } };

export type PublicSessionTicketsQueryVariables = Exact<{
  sessionId: Scalars['Float'];
  eventId: Scalars['Float'];
  clientCodename: Scalars['String'];
}>;


export type PublicSessionTicketsQuery = { __typename?: 'Query', getSessionTickets: { __typename?: 'Client', id: number, name: string, codename: string, navbarBackgroundColor?: Maybe<string>, navbarTextColor?: Maybe<string>, bodyBackgroundColor?: Maybe<string>, bodyTextColor?: Maybe<string>, backgroundColor?: Maybe<string>, mainButtonBackgroundColor?: Maybe<string>, mainButtonTextColor?: Maybe<string>, backgroundImage?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, logo?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, events?: Maybe<Array<{ __typename?: 'Event', id: number, active: boolean, name: string, description: string, artistName: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, sessions?: Maybe<Array<{ __typename?: 'Session', id: number, name: string, inscriptionStart: any, inscriptionEnd: any, start: any, end: any, country: string, city: string, postalCode: string, state: string, place: string, capacity: number, soldTickets?: Maybe<number>, ticketsSession: Array<{ __typename?: 'TicketSession', maxInscriptions: number, soldSessionTicket?: Maybe<number>, ticket: { __typename?: 'Ticket', id: number, name: string, description: string } }> }>> }>> } };

export type AddEventMutationMutationVariables = Exact<{
  addEventParams: AddEditEventInput;
}>;


export type AddEventMutationMutation = { __typename?: 'Mutation', addEvent: { __typename?: 'Event', id: number } };

export type DeleteEventMutationMutationVariables = Exact<{
  deleteEventEventId: Scalars['Float'];
}>;


export type DeleteEventMutationMutation = { __typename?: 'Mutation', deleteEvent: boolean };

export type GetEventsAdminQueryVariables = Exact<{
  getEventsAdminClientId: Scalars['Float'];
}>;


export type GetEventsAdminQuery = { __typename?: 'Query', getEventsAdmin: Array<{ __typename?: 'Event', id: number, name: string, artistName: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, sessions?: Maybe<Array<{ __typename?: 'Session', start: any, end: any }>> }> };

export type CreateInscriptionsFromBlockedInscriptionsMutationVariables = Exact<{
  clientCodename: Scalars['String'];
  blockedInscriptionsIds: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type CreateInscriptionsFromBlockedInscriptionsMutation = { __typename?: 'Mutation', createInscriptionsFromBlockedInscriptions: boolean };

export type GetMyUpcomingTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUpcomingTicketsQuery = { __typename?: 'Query', getMyUpcomingTickets: Array<{ __typename?: 'Inscription', id: number, groupId: number, event: { __typename?: 'Event', id: number, name: string, description: string, artistName: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, client?: Maybe<{ __typename?: 'Client', name: string }> }, ticket: { __typename?: 'Ticket', name: string }, session: { __typename?: 'Session', id: number, start: any, end: any, place: string, state: string, postalCode: string, city: string, country: string } }> };

export type GetMyPastTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPastTicketsQuery = { __typename?: 'Query', getMyPastTickets: Array<{ __typename?: 'Inscription', id: number, groupId: number, event: { __typename?: 'Event', name: string, description: string, artistName: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, client?: Maybe<{ __typename?: 'Client', name: string }> }, ticket: { __typename?: 'Ticket', name: string }, session: { __typename?: 'Session', start: any, end: any, place: string, state: string, postalCode: string, city: string, country: string } }> };

export type BlockInscriptionsMutationMutationVariables = Exact<{
  params: BlockInscriptionsInput;
}>;


export type BlockInscriptionsMutationMutation = { __typename?: 'Mutation', blockInscriptions: Array<{ __typename?: 'InscriptionBlocked', id: number, blockedDate: any, deleteDate: any }> };

export type ValidateBlockedInscriptionsMutationVariables = Exact<{
  clientCodename: Scalars['String'];
  blockedInscriptionsIds: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type ValidateBlockedInscriptionsMutation = { __typename?: 'Mutation', validateBlockedInscriptions: { __typename?: 'BlockInscriptionsReturn', client?: Maybe<{ __typename?: 'Client', id: number, name: string, codename: string, navbarBackgroundColor?: Maybe<string>, navbarTextColor?: Maybe<string>, bodyBackgroundColor?: Maybe<string>, bodyTextColor?: Maybe<string>, backgroundColor?: Maybe<string>, mainButtonBackgroundColor?: Maybe<string>, mainButtonTextColor?: Maybe<string>, backgroundImage?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }>, logo?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }> }>, event?: Maybe<{ __typename?: 'Event', id: number, name: string, description: string, artistName: string, poster?: Maybe<{ __typename?: 'Document', filename?: Maybe<string> }> }>, session?: Maybe<{ __typename?: 'Session', id: number, name: string, start: any, end: any, country: string, city: string, postalCode: string, state: string, place: string, capacity: number }>, inscriptionsBlocked?: Maybe<Array<{ __typename?: 'InscriptionBlocked', id: number, deleteDate: any, blockedDate: any, ticket: { __typename?: 'Ticket', id: number, name: string, description: string }, ticketSession: { __typename?: 'TicketSession', ticketOffice: boolean, price: number } }>> } };


export const PublicClientEventsDocument = gql`
    query publicClientEvents($clientCodename: String!) {
  getEventsClient(clientCodename: $clientCodename) {
    id
    events {
      id
      name
      artistName
      poster {
        filename
      }
      sessions {
        end
        start
      }
    }
    name
    backgroundImage {
      filename
    }
    logo {
      filename
    }
    navbarBackgroundColor
    navbarTextColor
    bodyBackgroundColor
    bodyTextColor
    backgroundColor
    mainButtonBackgroundColor
    mainButtonTextColor
  }
}
    `;

/**
 * __usePublicClientEventsQuery__
 *
 * To run a query within a React component, call `usePublicClientEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientEventsQuery({
 *   variables: {
 *      clientCodename: // value for 'clientCodename'
 *   },
 * });
 */
export function usePublicClientEventsQuery(baseOptions: Apollo.QueryHookOptions<PublicClientEventsQuery, PublicClientEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicClientEventsQuery, PublicClientEventsQueryVariables>(PublicClientEventsDocument, options);
      }
export function usePublicClientEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicClientEventsQuery, PublicClientEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicClientEventsQuery, PublicClientEventsQueryVariables>(PublicClientEventsDocument, options);
        }
export type PublicClientEventsQueryHookResult = ReturnType<typeof usePublicClientEventsQuery>;
export type PublicClientEventsLazyQueryHookResult = ReturnType<typeof usePublicClientEventsLazyQuery>;
export type PublicClientEventsQueryResult = Apollo.QueryResult<PublicClientEventsQuery, PublicClientEventsQueryVariables>;
export const PublicEventInfoDocument = gql`
    query publicEventInfo($eventId: Float!, $clientCodename: String!) {
  getEventSelected(eventId: $eventId, clientCodename: $clientCodename) {
    id
    name
    codename
    navbarBackgroundColor
    navbarTextColor
    bodyBackgroundColor
    bodyTextColor
    backgroundColor
    mainButtonBackgroundColor
    mainButtonTextColor
    backgroundImage {
      filename
    }
    logo {
      filename
    }
    events {
      id
      name
      artistName
      description
      poster {
        filename
      }
      sessions {
        capacity
        place
        state
        postalCode
        city
        country
        end
        start
        inscriptionEnd
        inscriptionStart
        name
        id
        soldTickets
      }
    }
  }
}
    `;

/**
 * __usePublicEventInfoQuery__
 *
 * To run a query within a React component, call `usePublicEventInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicEventInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicEventInfoQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      clientCodename: // value for 'clientCodename'
 *   },
 * });
 */
export function usePublicEventInfoQuery(baseOptions: Apollo.QueryHookOptions<PublicEventInfoQuery, PublicEventInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicEventInfoQuery, PublicEventInfoQueryVariables>(PublicEventInfoDocument, options);
      }
export function usePublicEventInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicEventInfoQuery, PublicEventInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicEventInfoQuery, PublicEventInfoQueryVariables>(PublicEventInfoDocument, options);
        }
export type PublicEventInfoQueryHookResult = ReturnType<typeof usePublicEventInfoQuery>;
export type PublicEventInfoLazyQueryHookResult = ReturnType<typeof usePublicEventInfoLazyQuery>;
export type PublicEventInfoQueryResult = Apollo.QueryResult<PublicEventInfoQuery, PublicEventInfoQueryVariables>;
export const PublicSessionTicketsDocument = gql`
    query PublicSessionTickets($sessionId: Float!, $eventId: Float!, $clientCodename: String!) {
  getSessionTickets(
    sessionId: $sessionId
    eventId: $eventId
    clientCodename: $clientCodename
  ) {
    id
    name
    codename
    navbarBackgroundColor
    navbarTextColor
    bodyBackgroundColor
    bodyTextColor
    backgroundColor
    mainButtonBackgroundColor
    mainButtonTextColor
    backgroundImage {
      filename
    }
    logo {
      filename
    }
    events {
      id
      active
      name
      description
      artistName
      poster {
        filename
      }
      sessions {
        id
        name
        inscriptionStart
        inscriptionEnd
        start
        end
        country
        city
        postalCode
        state
        place
        capacity
        soldTickets
        ticketsSession {
          maxInscriptions
          ticket {
            id
            name
            description
          }
          soldSessionTicket
        }
      }
    }
  }
}
    `;

/**
 * __usePublicSessionTicketsQuery__
 *
 * To run a query within a React component, call `usePublicSessionTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSessionTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSessionTicketsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      eventId: // value for 'eventId'
 *      clientCodename: // value for 'clientCodename'
 *   },
 * });
 */
export function usePublicSessionTicketsQuery(baseOptions: Apollo.QueryHookOptions<PublicSessionTicketsQuery, PublicSessionTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicSessionTicketsQuery, PublicSessionTicketsQueryVariables>(PublicSessionTicketsDocument, options);
      }
export function usePublicSessionTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicSessionTicketsQuery, PublicSessionTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicSessionTicketsQuery, PublicSessionTicketsQueryVariables>(PublicSessionTicketsDocument, options);
        }
export type PublicSessionTicketsQueryHookResult = ReturnType<typeof usePublicSessionTicketsQuery>;
export type PublicSessionTicketsLazyQueryHookResult = ReturnType<typeof usePublicSessionTicketsLazyQuery>;
export type PublicSessionTicketsQueryResult = Apollo.QueryResult<PublicSessionTicketsQuery, PublicSessionTicketsQueryVariables>;
export const AddEventMutationDocument = gql`
    mutation AddEventMutation($addEventParams: AddEditEventInput!) {
  addEvent(params: $addEventParams) {
    id
  }
}
    `;
export type AddEventMutationMutationFn = Apollo.MutationFunction<AddEventMutationMutation, AddEventMutationMutationVariables>;

/**
 * __useAddEventMutationMutation__
 *
 * To run a mutation, you first call `useAddEventMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutationMutation, { data, loading, error }] = useAddEventMutationMutation({
 *   variables: {
 *      addEventParams: // value for 'addEventParams'
 *   },
 * });
 */
export function useAddEventMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddEventMutationMutation, AddEventMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEventMutationMutation, AddEventMutationMutationVariables>(AddEventMutationDocument, options);
      }
export type AddEventMutationMutationHookResult = ReturnType<typeof useAddEventMutationMutation>;
export type AddEventMutationMutationResult = Apollo.MutationResult<AddEventMutationMutation>;
export type AddEventMutationMutationOptions = Apollo.BaseMutationOptions<AddEventMutationMutation, AddEventMutationMutationVariables>;
export const DeleteEventMutationDocument = gql`
    mutation DeleteEventMutation($deleteEventEventId: Float!) {
  deleteEvent(eventId: $deleteEventEventId)
}
    `;
export type DeleteEventMutationMutationFn = Apollo.MutationFunction<DeleteEventMutationMutation, DeleteEventMutationMutationVariables>;

/**
 * __useDeleteEventMutationMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutationMutation, { data, loading, error }] = useDeleteEventMutationMutation({
 *   variables: {
 *      deleteEventEventId: // value for 'deleteEventEventId'
 *   },
 * });
 */
export function useDeleteEventMutationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutationMutation, DeleteEventMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutationMutation, DeleteEventMutationMutationVariables>(DeleteEventMutationDocument, options);
      }
export type DeleteEventMutationMutationHookResult = ReturnType<typeof useDeleteEventMutationMutation>;
export type DeleteEventMutationMutationResult = Apollo.MutationResult<DeleteEventMutationMutation>;
export type DeleteEventMutationMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutationMutation, DeleteEventMutationMutationVariables>;
export const GetEventsAdminDocument = gql`
    query GetEventsAdmin($getEventsAdminClientId: Float!) {
  getEventsAdmin(clientId: $getEventsAdminClientId) {
    id
    name
    artistName
    poster {
      filename
    }
    sessions {
      start
      end
    }
  }
}
    `;

/**
 * __useGetEventsAdminQuery__
 *
 * To run a query within a React component, call `useGetEventsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsAdminQuery({
 *   variables: {
 *      getEventsAdminClientId: // value for 'getEventsAdminClientId'
 *   },
 * });
 */
export function useGetEventsAdminQuery(baseOptions: Apollo.QueryHookOptions<GetEventsAdminQuery, GetEventsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsAdminQuery, GetEventsAdminQueryVariables>(GetEventsAdminDocument, options);
      }
export function useGetEventsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsAdminQuery, GetEventsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsAdminQuery, GetEventsAdminQueryVariables>(GetEventsAdminDocument, options);
        }
export type GetEventsAdminQueryHookResult = ReturnType<typeof useGetEventsAdminQuery>;
export type GetEventsAdminLazyQueryHookResult = ReturnType<typeof useGetEventsAdminLazyQuery>;
export type GetEventsAdminQueryResult = Apollo.QueryResult<GetEventsAdminQuery, GetEventsAdminQueryVariables>;
export const CreateInscriptionsFromBlockedInscriptionsDocument = gql`
    mutation CreateInscriptionsFromBlockedInscriptions($clientCodename: String!, $blockedInscriptionsIds: [Float!]!) {
  createInscriptionsFromBlockedInscriptions(
    clientCodename: $clientCodename
    blockedInscriptionsIds: $blockedInscriptionsIds
  )
}
    `;
export type CreateInscriptionsFromBlockedInscriptionsMutationFn = Apollo.MutationFunction<CreateInscriptionsFromBlockedInscriptionsMutation, CreateInscriptionsFromBlockedInscriptionsMutationVariables>;

/**
 * __useCreateInscriptionsFromBlockedInscriptionsMutation__
 *
 * To run a mutation, you first call `useCreateInscriptionsFromBlockedInscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInscriptionsFromBlockedInscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInscriptionsFromBlockedInscriptionsMutation, { data, loading, error }] = useCreateInscriptionsFromBlockedInscriptionsMutation({
 *   variables: {
 *      clientCodename: // value for 'clientCodename'
 *      blockedInscriptionsIds: // value for 'blockedInscriptionsIds'
 *   },
 * });
 */
export function useCreateInscriptionsFromBlockedInscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateInscriptionsFromBlockedInscriptionsMutation, CreateInscriptionsFromBlockedInscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInscriptionsFromBlockedInscriptionsMutation, CreateInscriptionsFromBlockedInscriptionsMutationVariables>(CreateInscriptionsFromBlockedInscriptionsDocument, options);
      }
export type CreateInscriptionsFromBlockedInscriptionsMutationHookResult = ReturnType<typeof useCreateInscriptionsFromBlockedInscriptionsMutation>;
export type CreateInscriptionsFromBlockedInscriptionsMutationResult = Apollo.MutationResult<CreateInscriptionsFromBlockedInscriptionsMutation>;
export type CreateInscriptionsFromBlockedInscriptionsMutationOptions = Apollo.BaseMutationOptions<CreateInscriptionsFromBlockedInscriptionsMutation, CreateInscriptionsFromBlockedInscriptionsMutationVariables>;
export const GetMyUpcomingTicketsDocument = gql`
    query GetMyUpcomingTickets {
  getMyUpcomingTickets {
    id
    groupId
    event {
      id
      name
      description
      poster {
        filename
      }
      artistName
      client {
        name
      }
    }
    ticket {
      name
    }
    session {
      id
      start
      end
      place
      state
      postalCode
      city
      country
    }
  }
}
    `;

/**
 * __useGetMyUpcomingTicketsQuery__
 *
 * To run a query within a React component, call `useGetMyUpcomingTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUpcomingTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUpcomingTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUpcomingTicketsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUpcomingTicketsQuery, GetMyUpcomingTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUpcomingTicketsQuery, GetMyUpcomingTicketsQueryVariables>(GetMyUpcomingTicketsDocument, options);
      }
export function useGetMyUpcomingTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUpcomingTicketsQuery, GetMyUpcomingTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUpcomingTicketsQuery, GetMyUpcomingTicketsQueryVariables>(GetMyUpcomingTicketsDocument, options);
        }
export type GetMyUpcomingTicketsQueryHookResult = ReturnType<typeof useGetMyUpcomingTicketsQuery>;
export type GetMyUpcomingTicketsLazyQueryHookResult = ReturnType<typeof useGetMyUpcomingTicketsLazyQuery>;
export type GetMyUpcomingTicketsQueryResult = Apollo.QueryResult<GetMyUpcomingTicketsQuery, GetMyUpcomingTicketsQueryVariables>;
export const GetMyPastTicketsDocument = gql`
    query GetMyPastTickets {
  getMyPastTickets {
    id
    groupId
    event {
      name
      description
      poster {
        filename
      }
      artistName
      client {
        name
      }
    }
    ticket {
      name
    }
    session {
      start
      end
      place
      state
      postalCode
      city
      country
    }
  }
}
    `;

/**
 * __useGetMyPastTicketsQuery__
 *
 * To run a query within a React component, call `useGetMyPastTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPastTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPastTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPastTicketsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPastTicketsQuery, GetMyPastTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPastTicketsQuery, GetMyPastTicketsQueryVariables>(GetMyPastTicketsDocument, options);
      }
export function useGetMyPastTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPastTicketsQuery, GetMyPastTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPastTicketsQuery, GetMyPastTicketsQueryVariables>(GetMyPastTicketsDocument, options);
        }
export type GetMyPastTicketsQueryHookResult = ReturnType<typeof useGetMyPastTicketsQuery>;
export type GetMyPastTicketsLazyQueryHookResult = ReturnType<typeof useGetMyPastTicketsLazyQuery>;
export type GetMyPastTicketsQueryResult = Apollo.QueryResult<GetMyPastTicketsQuery, GetMyPastTicketsQueryVariables>;
export const BlockInscriptionsMutationDocument = gql`
    mutation BlockInscriptionsMutation($params: BlockInscriptionsInput!) {
  blockInscriptions(params: $params) {
    id
    blockedDate
    deleteDate
  }
}
    `;
export type BlockInscriptionsMutationMutationFn = Apollo.MutationFunction<BlockInscriptionsMutationMutation, BlockInscriptionsMutationMutationVariables>;

/**
 * __useBlockInscriptionsMutationMutation__
 *
 * To run a mutation, you first call `useBlockInscriptionsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockInscriptionsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockInscriptionsMutationMutation, { data, loading, error }] = useBlockInscriptionsMutationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useBlockInscriptionsMutationMutation(baseOptions?: Apollo.MutationHookOptions<BlockInscriptionsMutationMutation, BlockInscriptionsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockInscriptionsMutationMutation, BlockInscriptionsMutationMutationVariables>(BlockInscriptionsMutationDocument, options);
      }
export type BlockInscriptionsMutationMutationHookResult = ReturnType<typeof useBlockInscriptionsMutationMutation>;
export type BlockInscriptionsMutationMutationResult = Apollo.MutationResult<BlockInscriptionsMutationMutation>;
export type BlockInscriptionsMutationMutationOptions = Apollo.BaseMutationOptions<BlockInscriptionsMutationMutation, BlockInscriptionsMutationMutationVariables>;
export const ValidateBlockedInscriptionsDocument = gql`
    mutation ValidateBlockedInscriptions($clientCodename: String!, $blockedInscriptionsIds: [Float!]!) {
  validateBlockedInscriptions(
    clientCodename: $clientCodename
    blockedInscriptionsIds: $blockedInscriptionsIds
  ) {
    client {
      id
      name
      codename
      navbarBackgroundColor
      navbarTextColor
      bodyBackgroundColor
      bodyTextColor
      backgroundColor
      mainButtonBackgroundColor
      mainButtonTextColor
      backgroundImage {
        filename
      }
      logo {
        filename
      }
    }
    event {
      id
      name
      description
      artistName
      poster {
        filename
      }
    }
    session {
      id
      name
      start
      end
      country
      city
      postalCode
      state
      place
      capacity
    }
    inscriptionsBlocked {
      id
      deleteDate
      blockedDate
      ticket {
        id
        name
        description
      }
      ticketSession {
        ticketOffice
        price
      }
    }
  }
}
    `;
export type ValidateBlockedInscriptionsMutationFn = Apollo.MutationFunction<ValidateBlockedInscriptionsMutation, ValidateBlockedInscriptionsMutationVariables>;

/**
 * __useValidateBlockedInscriptionsMutation__
 *
 * To run a mutation, you first call `useValidateBlockedInscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateBlockedInscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateBlockedInscriptionsMutation, { data, loading, error }] = useValidateBlockedInscriptionsMutation({
 *   variables: {
 *      clientCodename: // value for 'clientCodename'
 *      blockedInscriptionsIds: // value for 'blockedInscriptionsIds'
 *   },
 * });
 */
export function useValidateBlockedInscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<ValidateBlockedInscriptionsMutation, ValidateBlockedInscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateBlockedInscriptionsMutation, ValidateBlockedInscriptionsMutationVariables>(ValidateBlockedInscriptionsDocument, options);
      }
export type ValidateBlockedInscriptionsMutationHookResult = ReturnType<typeof useValidateBlockedInscriptionsMutation>;
export type ValidateBlockedInscriptionsMutationResult = Apollo.MutationResult<ValidateBlockedInscriptionsMutation>;
export type ValidateBlockedInscriptionsMutationOptions = Apollo.BaseMutationOptions<ValidateBlockedInscriptionsMutation, ValidateBlockedInscriptionsMutationVariables>;