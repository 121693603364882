import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CardContainer from "../../../components/containers/cardContainer/CardContainer";
import useApi from "../../../hooks/api/useApi";
import useAuth from "../../../hooks/auth/useAuth";
import showToast from "../../../utils/toastUtils";
import { stringHasLettersAndNumbers, validateEmail } from "../../../utils/validators";
import styles from "./SignIn.module.css";

export default function SignIn() {
  let history = useHistory();
  let location = useLocation();
  const auth = useAuth();
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const [errors,setErrors] :any= useState([])
  const [showPassword,setShowPassword] = useState(false)
  const [loading,setLoading] = useState(false)
  const {apiPost,apiRoutes} = useApi()
  const isMounted = useRef(false);
  const [remember,setRemember] = useState(true)
  useEffect(() => {
    if(auth.user && !auth.loading){
      auth.signout(() => {
        let { from }: any = location.state || { from: { pathname: "/" } };
        from = auth.getRouteAfterAuthenticate(from);
        history.replace(from)
      })
    }
  },[])

  function checkErrors(){
    const newErrors = []
    if (!email || email.trim().length === 0 || !validateEmail(email)) {
      newErrors.push("email");
    }
    
    if (
      !password ||
      password.trim().length < 5 ||
      password.trim().length > 60 ||
      !stringHasLettersAndNumbers(password)
    ) {
      newErrors.push("password");
    }

    setErrors(newErrors)
    return newErrors
  }

  async function siginin(e:FormEvent) {
   e.preventDefault()
   const new_errors = await checkErrors()
   if(new_errors.length===0 && !loading){
     setLoading(true)
     const params = {
      email,
      password
    }
    const res = await apiPost(apiRoutes.signin,params)
    if(res.success && res.data.user){
      showToast("success",{message: "Autenticació realitzada correctament!"})
      let { from }: any = location.state || { from: { pathname: "/" } };
      from = auth.getRouteAfterAuthenticate(from);
      auth.signin(res.data.user, () =>{
        history.replace(from)
      },remember)
    } else{
      console.log("error")
      showToast("error",{message: "Ja existeix un usuari amb aquest correu electrònic!"})
      setErrors([...errors,'password'])
      setLoading(false)
    }
   }
  }

  return (
    <CardContainer className={styles.signInContainer}>
      <CardContainer.Header>
        <CardContainer.HeaderTitle>Iniciar Sessió</CardContainer.HeaderTitle>
        <CardContainer.Body>
          <form>
            <CardContainer.InputGroup>
            <CardContainer.Input
                type="email"
                value={email}
                error={errors.includes("email")}
                label="Correu electrònic"
                onChange={(e: any) => setEmail(e.target.value)}
                helperText={errors.includes("email")? "Introdueix un email vàlid i no existent!":""}
              />
            </CardContainer.InputGroup>
            <CardContainer.InputGroup>
            <CardContainer.PasswordInput
                error={errors.includes("password")}
                label="Contrasenya"
                value={password}
                showPassword={showPassword}
                showPasswordFunction={() => setShowPassword(!showPassword)}
                onChange={(e: any) => setPassword(e.target.value)}
                helperText={errors.includes("password")? "La contrasenya no és vàlida!":"" }
              />
            </CardContainer.InputGroup>
            <div className={styles.linkForgetContainer}>
            <FormControlLabel control={<Checkbox checked={remember} onClick={() => setRemember(!remember)} />} label="Recorda'm"/>
              <CardContainer.Link to={"/signup"} className={styles.signinLink}>
                Has oblidat la contrasenya?
              </CardContainer.Link>
            </div>
            <CardContainer.Button onClick={(e:FormEvent) => siginin(e)} disabled={loading}>Iniciar sessió</CardContainer.Button>
            <CardContainer.Link to={"/signup"} className={styles.signinLinkBottom} disabled={loading}>
              Crear un compte
            </CardContainer.Link>
          </form>
        </CardContainer.Body>
      </CardContainer.Header>
    </CardContainer>
  );
}
