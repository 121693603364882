import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderBreadcrumb from "../../../../components/navigation/HeaderBreadcrumb/HeaderBreadcrumb";
import PageBody from "../../../../components/parts/pageBody/PageBody";
import PageTitle from "../../../../components/parts/pageTitle/PageTitle";
import { RiAddFill } from "react-icons/ri";
import CardItemContainer from "../../../../components/containers/cardItemContainer/CardItemContainer";
import Line from "../../../../components/parts/line/Line";
import { HiPencil, HiTrash } from "react-icons/hi";
import styles from "./EventList.module.css";
import { useHistory } from "react-router-dom";
import {
  useDeleteEventMutationMutation,
  useGetEventsAdminQuery,
} from "../../../../generated/graphql";
import useAuth from "../../../../hooks/auth/useAuth";
import moment from "moment";
import { API_BASE, EVENT_IMAGE_BASE_ROUTE } from "../../../../hooks/api/useApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { processEvent } from "../../../../utils/eventsUtils";

export default function EventList() {
  const history = useHistory();
  const auth = useAuth();
  const eventQuery = useGetEventsAdminQuery({
    variables: { getEventsAdminClientId: auth.client.id },
  });
  const [events, setEvents]: any = useState([]);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [deleteEvent] = useDeleteEventMutationMutation();
  const [deleteIndex, setDeleteIndex] = useState(0);
  useEffect(() => {
    if (!eventQuery.loading && eventQuery.data) {
      const data = eventQuery.data.getEventsAdmin;
      const newEvents: any = [];
      data.forEach((event) => {        
        newEvents.push(processEvent(event));
      });
      console.log("data", data);
      setEvents(newEvents);
    }
  }, [eventQuery.loading, eventQuery.data]);

  async function deleteEventFunction() {
    setDialogOpened(false);
    const eventDelete = events[deleteIndex];
    const { data } = await deleteEvent({
      variables: { deleteEventEventId: eventDelete.id },
    });

    if (data?.deleteEvent) {
      const newEvents = [...events];
      newEvents.splice(deleteIndex, 1);
      setEvents(newEvents);
      setDeleteIndex(0);
    }
  }

  function openDeleteDialog(index: number) {
    setDeleteIndex(index);
    setDialogOpened(true);
  }

  const deleteDialog = (
    <Dialog open={dialogOpened}>
      <DialogTitle>Confirmació!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Estàs segur de que vols eliminar l'esdeveniment?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setDialogOpened(false)}>
          Cancel·lar
        </Button>
        <Button onClick={() => deleteEventFunction()}>Eliminar</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <React.Fragment>
      {deleteDialog}
      <PageTitle>
        <PageTitle.Title>Esdeveniments</PageTitle.Title>
        <HeaderBreadcrumb options={breadcumOptions} />
      </PageTitle>
      <PageBody>
        <div className={styles.createButtonContainer}>
          <Button
            variant="contained"
            className={styles.createButton}
            onClick={() => history.push("/admin/events/create")}
          >
            <RiAddFill size={18} />
            Crear un esdeveniment
          </Button>
        </div>
        <div className={styles.eventList}>
          {events.map((event: any, index: number) => (
            <CardItemContainer
              key={index}
              style={{
                borderTopLeftRadius: index === 0 ? 10 : 0,
                borderTopRightRadius: index === 0 ? 10 : 0,
                borderBottomLeftRadius: index === events.length - 1 ? 10 : 0,
                borderBottomRightRadius: index === events.length - 1 ? 10 : 0,
                borderBottom:
                  index === events.length - 1
                    ? "0.5px solid lightgray"
                    : "none",
              }}
            >
              <CardItemContainer.FirstCol>
                <div className={styles.firstColContainer}>
                  <div style={{ textTransform: "uppercase" }}>
                    {event.startDate}
                  </div>
                  <Line />
                  {event.startDate !== event.endDate && (
                    <div style={{ textTransform: "uppercase" }}>
                      {event.endDate}
                    </div>
                  )}
                </div>
              </CardItemContainer.FirstCol>
              <CardItemContainer.MiddleCol>
                <CardItemContainer.Image src={event.poster} alt="Event"/>
                <div className={styles.eventInfoContainer}>
                  <div className={styles.eventInfoMainContainer}>
                    <div className={styles.eventTextContainer}>
                      <label
                        className={`${styles.eventTextLabel} ${styles.titleLabel}`}
                      >
                        {event.name}
                      </label>
                    </div>
                    <Line />
                    <label
                      className={`${styles.eventTextLabel} ${styles.infoLabel}`}
                    >
                      {event.artistName}
                    </label>
                  </div>
                </div>
              </CardItemContainer.MiddleCol>
              <CardItemContainer.LastCol>
                <IconButton title="Editar">
                  <HiPencil size={24} color={"#18A1E2"} />
                </IconButton>
                <IconButton
                  title="Eliminar"
                  onClick={() => openDeleteDialog(index)}
                >
                  <HiTrash size={24} color={"#e22718"} />
                </IconButton>
              </CardItemContainer.LastCol>
            </CardItemContainer>
          ))}
        </div>
      </PageBody>
    </React.Fragment>
  );
}

const breadcumOptions: any = [
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Llistat d'esdeveniments",
    link: "/admin/events",
  },
];
