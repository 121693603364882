export default function AppInitReducer(
    state: boolean = false,
    action: any
  ) {
    switch (action.type) {
      case "APP_INIT":
        return true;
      case "APP_NO_INIT":
        return false;
      default:
        return state;
    }
  }
  
  AppInitReducer.actions = {
    init: { type: "APP_INIT" },
    no_init: { type: "APP_NO_INIT" },
  };
  