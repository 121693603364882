import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export default function TabsContainer({ children, ...rest }: any) {
  return (
    <Box sx={{ width: '100%' }} {...rest}>
    {children}  
    </Box>
  );
}

TabsContainer.TabsContainer = ({children,...rest}:any) => (
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }} {...rest}>
    {children}
  </Box>
)

TabsContainer.Tabs = ({children,...rest}:any) => (
  <Tabs {...rest}>
    {children}
  </Tabs>
)

TabsContainer.Tab = ({ label = "", ...rest }: any) => (
  <Tab label={label} {...rest} />
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

TabsContainer.TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...rest}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};
