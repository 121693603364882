import moment from "moment";

export function sessionToSessionConfig(session:any = {}){
    const config = {
        id: session.id,
        capacity: session.capacity ?? 0,
        city: session.city ?? "",
        country: session.country ?? "",
        name: session.name ?? "",
        place: session.place ?? "",
        state: session.state ?? "",
        postalCode: session.postalCode ?? "",
        soldTickets: session.soldTickets ?? 0,
        start: session.start? moment(session.start):moment(),
        end: session.end? moment(session.end):moment(),
        inscriptionStart: session.inscriptionStart? moment(session.inscriptionStart):moment(),
        inscriptionEnd: session.inscriptionEnd? moment(session.inscriptionEnd):moment(),
        allowAddTickets: -1
    }

    config.allowAddTickets = session.capacity>0 ? session.capacity - session.soldTickets: -1;
    return config
}