export default function AuthUserPermissionsReducer(
    state: any = [],
    action: any
  ) {
    switch (action.type) {
      case "SET_USER_PERMISSIONS":
        return action.payload;
      case "REMOVE_USER_PERMISSIONS":
        return [];
      default:
        return state;
    }
  }
  
  AuthUserPermissionsReducer.actions = (permissions:any = []) => ({
    setPermissions: { type: "SET_USER_PERMISSIONS", payload: permissions },
    removePermissions: { type: "REMOVE_USER_PERMISSIONS", payload: [] },
  });
  