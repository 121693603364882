import useIsMobileSize from '../../../hooks/windowSize/useIsMobileSize'
import styles from './PageTitle.module.css'

export default function PageTitle({className = "", children, ...rest}:any){
    const isMobileSize = useIsMobileSize()

    return (
        <div className={`${!isMobileSize? styles.containerPc:styles.containerMobile}`} {...rest}> 
            {children}
        </div>
    )
}

PageTitle.Title = ({className = "", children, ...rest}:any) => (
    <h1 className={`${styles.title} ${className}`} {...rest}>{children}</h1>
)
