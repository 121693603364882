import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DefaultBodyContainer from "../../../components/containers/bodyContainer/DefaultBodyContainer";
import Navbar from "../../../components/navigation/header/navbar/Navbar";
import { useBlockInscriptionsMutationMutation, usePublicSessionTicketsQuery } from "../../../generated/graphql";
import { EVENT_IMAGE_BASE_ROUTE } from "../../../hooks/api/useApi";
import styles from "./EventTickets.module.css";
import { clientToClientConfig } from "../../../utils/clientUtils";
import { sessionToSessionConfig } from "../../../utils/sessionUtils";
import LoaderContainer from "../../../components/containers/loaderContainer/LoaderContainer";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import colors from "../../../constants/colors";
import { IoAddOutline, IoRemoveOutline, IoTicketSharp } from "react-icons/io5";
import { BsInfo } from "react-icons/bs";
import { sessionTicketToConfig } from "../../../utils/sessionTicketUtils";
import {
  StyledBuyButtonEventTicket,
  StyledCardHeaderEventTicket,
  StyledHeaderBackgroundEventTicket,
  StyledHeaderContainerEventTicket,
  StyledIconButtonEventTicket,
} from "./useEventTicketsStyled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AuthModal from "../../../components/modals/auth/AuthModal";
import useAuth from "../../../hooks/auth/useAuth";
import { useDispatch } from "react-redux";
import showToast from "../../../utils/toastUtils";
import { useBlockedInscriptionsIds } from "../../../hooks/blockedInscriptions/useBlockedInscriptions";

export function EventTickets() {
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch()
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [event, setEvent]: any = useState({});
  const [session, setSession]: any = useState({});
  const [tickets, setTickets]: any = useState([]);
  const [ticketsAdded, setTicketsAdded] = useState(0);
  const [clientConfig, setClientConfig]: any = useState({});
  const [dialogOpened,setDialogOpened] = useState(false)
  const [dialogTicket,setDialogTicket] :any= useState({})
  const [authModalOpened, setAuthModalOpened] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const auth = useAuth()
  const [init,setInit] = useState(false)
  const [blockInscriptions] = useBlockInscriptionsMutationMutation()
  const {setBlockedInscriptionsIds, removeBlockedInscriptionsIds} = useBlockedInscriptionsIds()

  const { data, loading, error } = usePublicSessionTicketsQuery({
    variables: {
      sessionId: Number(params.sessionId),
      eventId: Number(params.eventId),
      clientCodename: params.clientCodename,
    },
  });

  useEffect(() => {
    removeBlockedInscriptionsIds()
  },[])

  useEffect(() => {
    if (!loading && data && !error && !init) {
      setTicketsAdded(0)
      const res = data.getSessionTickets;
      if (!res.events || res.events.length !== 1) {
        history.replace("/");
      }
      console.log(res)
      //SET CLIENT CONFIG
      setClientConfig(clientToClientConfig(res));
      //SET EVENT
      const event: any = { ...res.events![0] };
      event.poster = EVENT_IMAGE_BASE_ROUTE + event.poster?.filename;
      setEvent(event);
      //SET SESSIONS
      const session: any = sessionToSessionConfig({
        ...res.events![0].sessions![0],
      });
      setSession(session);
      //SET TICKETS
      const sessionTickets = res.events![0].sessions![0].ticketsSession;
      const newTicketArray: any = [];
      sessionTickets.forEach((sessionTicket) => {
        newTicketArray.push(sessionTicketToConfig(sessionTicket));
      });
      setTickets(newTicketArray);
      setLoadingInfo(false);
      setInit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  function getCardContainerStyle(session: any, index: number) {
    const style = {
      backgroundColor: clientConfig.bodyBackgroundColor ?? "#FFFFFF",
      color: clientConfig.bodyColor ?? colors.body.textColor,
    };
    if (session.capacity > 0 && session.capacity - session.soldTickets <= 0) {
      style.backgroundColor = "lightgray";
      style.color = "gray";
    }
    return style;
  }

  function addRemoveTicket(index: number, quantity: number) {
    const newTickets = [...tickets];
    newTickets[index].addButtonDisabled = addButtonDisabled(
      newTickets[index],
      quantity
    );
    newTickets[index].added += quantity;
    setTickets(newTickets);
    const newTicketsAdded = ticketsAdded + quantity;
    setTicketsAdded(newTicketsAdded);
  }

  function addButtonDisabled(ticket: any, addQuantity = 0) {
    if (
      session.allowAddTickets > -1 &&
      ticketsAdded + addQuantity >= session.allowAddTickets
    ) {
      return true;
    }

    if (
      ticket.allowMaxTickets > -1 &&
      ticket.added + addQuantity >= ticket.allowMaxTickets
    ) {
      return true;
    }

    return false;
  }

  function openInfoTicketDialog(ticket:any){
    setDialogTicket(ticket)
    setDialogOpened(true)
  }

  function closeInfoTicketDialog(){
    setDialogTicket({})
    setDialogOpened(false)
  }

  async function buyClicked() {
    setButtonDisabled(true)
    if (auth.user) {
      //TODO: BLOCK INSCRIPTIONS
      const params :any= {
        clientId: clientConfig.id,
        sessionId: session.id,
        eventId: event.id,
        ticketsIdWithQuantity: []
      }
      tickets.forEach((ticket:any) => {
          if(ticket.added && ticket.added>0){
            params.ticketsIdWithQuantity.push({id: ticket.ticketId, quantity: ticket.added})
          }
      });

      const res = await blockInscriptions({variables: {params}})
      if(res.data && res.data.blockInscriptions){
        console.log("res",res.data.blockInscriptions)
        showToast("success",{message: "Les teves entrades s'han assegurat correctament!"})
        const blockedIds :Number[]= []
        res.data.blockInscriptions.forEach((blockedInscription) => {
          blockedIds.push(blockedInscription.id)
        })
        setBlockedInscriptionsIds(blockedIds)
        history.push(`/${clientConfig.codename}/checkout`)
      }else{
        showToast("error",{message: "No queden entrades suficients. Intenta-ho de nou!"})
        setButtonDisabled(false)
      }
    } else {
      setAuthModalOpened(true);
      setButtonDisabled(false)
    }
  }

  return (
    <React.Fragment>
      {!loadingInfo ? (
        <React.Fragment>
          <Navbar clientConfig={clientConfig} />
          <DefaultBodyContainer style={{ padding: 0 }} footer={false}>
            <div style={{ height: 200, color: "#FFFFFF", backgroundColor: 'transparent' }} />
            <StyledHeaderBackgroundEventTicket clientconfig={clientConfig} event={event} />
            <Dialog
              open={dialogOpened}
              keepMounted
              onClose={closeInfoTicketDialog}
            >
              <DialogTitle>{dialogTicket.name}</DialogTitle>
              <DialogContent style={{ whiteSpace: 'pre-line' }}>
                <DialogContentText>
                  {dialogTicket.description}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeInfoTicketDialog}>Tancar</Button>
              </DialogActions>
            </Dialog>

            <AuthModal
              setAuthModalOpened={setAuthModalOpened}
              opened={authModalOpened && !auth.user}
            />

            <StyledHeaderContainerEventTicket>
              <div className={styles.headerInfoContainer}>
                <div>
                  <img
                    src={event.poster}
                    alt={event.poster}
                    height={160}
                    className={styles.headerImage}
                  />
                </div>
                <div className={styles.headerTextContainer}>
                  <div className={styles.eventInfoName}>{event.name}</div>
                  <div className={styles.artistName}>{event.artistName}</div>
                  <div className={styles.headerDatesContainer}>
                    <div>
                      {session.start.format("DD/MM/YYYY")}{" "}
                      {session.start.format("HH:mm")}
                    </div>
                    <div className={styles.eventInfoSeparator}>-</div>
                    <div>
                      {session.end.format("DD/MM/YYYY")}{" "}
                      {session.end.format("HH:mm")}
                    </div>
                  </div>
                  <div className={styles.headerPlaceContainer}>
                    <div>{session.place}</div>
                    <div className={styles.eventInfoSeparator}>-</div>
                    <div>
                      {session.city} , {session.state} , {session.country}
                    </div>
                  </div>
                </div>
              </div>
            </StyledHeaderContainerEventTicket>
            <DefaultBodyContainer footer={false}>
              <StyledCardHeaderEventTicket
                className={styles.sessionsCardHeader}
                clientconfig={clientConfig}
              >
                <h3 style={{ fontSize: 18,userSelect:'none' }}>Entrades</h3>
              </StyledCardHeaderEventTicket>
              {tickets.map((ticket: any, index: number) => (
                <CardItemContainer
                  key={index}
                  style={getCardContainerStyle(session, index)}
                >
                  <CardItemContainer.MiddleCol
                    style={{
                      wordBreak: "break-all",
                      paddingLeft: 0,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <label className={styles.ticketName}>{ticket.name}</label>
                    <StyledIconButtonEventTicket
                      style={{
                        backgroundColor: colors.navbar.backgroundColor,
                        padding: 1,
                        marginLeft: 10,
                      }}
                      onClick={() =>openInfoTicketDialog(ticket)}
                    >
                      <BsInfo size={18} color="#FFFFFF" />
                    </StyledIconButtonEventTicket>
                  </CardItemContainer.MiddleCol>
                  <CardItemContainer.LastCol>
                    <StyledIconButtonEventTicket
                      onClick={(e: any) => addRemoveTicket(index, -1)}
                      aria-label="fingerprint"
                      style={{
                        backgroundColor: ticket.added > 0 ? "#e22718" : "gray",
                      }}
                      disabled={ticket.added <= 0}
                    >
                      <IoRemoveOutline color="#FFFFFF" size={27} />
                    </StyledIconButtonEventTicket>
                    <label className={styles.ticketQuantity}>
                      {ticket.added}
                    </label>
                    <StyledIconButtonEventTicket
                      onClick={(e: any) => addRemoveTicket(index, 1)}
                      aria-label="Add ticket"
                      style={{
                        backgroundColor: ticket.addButtonDisabled
                          ? "gray"
                          : colors.navbar.backgroundColor,
                      }}
                      disabled={ticket.addButtonDisabled}
                    >
                      <IoAddOutline color="#FFFFFF" size={27} />
                    </StyledIconButtonEventTicket>
                  </CardItemContainer.LastCol>
                </CardItemContainer>
              ))}

              <CardItemContainer style={{ height:'auto' }}>
                    <div className={styles.totalContainer}>
                        <IoTicketSharp style={{ marginRight:5 }}/> {ticketsAdded}
                    </div>
              </CardItemContainer>
              <CardItemContainer className={styles.buttonBuyContainer}>
                <StyledBuyButtonEventTicket clientconfig={clientConfig} variant="contained" onClick={buyClicked} disabled={ticketsAdded===0 || buttonDisabled}>Continuar</StyledBuyButtonEventTicket>
              </CardItemContainer>
            </DefaultBodyContainer>
          </DefaultBodyContainer>
        </React.Fragment>
      ) : (
        <LoaderContainer />
      )}
    </React.Fragment>
  );
}
