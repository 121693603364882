import moment from "moment";
import styles from "./InscriptionCheckout.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DefaultBodyContainer from "../../../components/containers/bodyContainer/DefaultBodyContainer";
import CardContainer from "../../../components/containers/cardContainer/CardContainer";
import LoaderContainer from "../../../components/containers/loaderContainer/LoaderContainer";
import Navbar from "../../../components/navigation/header/navbar/Navbar";
import {
  useCreateInscriptionsFromBlockedInscriptionsMutation,
  useValidateBlockedInscriptionsMutation,
} from "../../../generated/graphql";
import { clientToClientConfig } from "../../../utils/clientUtils";
import { processEvent } from "../../../utils/eventsUtils";
import { sessionToSessionConfig } from "../../../utils/sessionUtils";
import Line from "../../../components/parts/line/Line";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import { StyledFinishButtonInscriptionCheckout } from "./InscriptionCheckoutStyleds";
import { IoTicket, IoTicketOutline, IoTicketSharp } from "react-icons/io5";
import Checkbox from "@mui/material/Checkbox";
import showToast from "../../../utils/toastUtils";
import { useBlockedInscriptionsIds } from "../../../hooks/blockedInscriptions/useBlockedInscriptions";
import Countdown from 'react-countdown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import colors from "../../../constants/colors";

export default function InscriptionCheckout() {
  moment.locale("ca");
  const  {getBlockedInscriptionsIds,removeBlockedInscriptionsIds} = useBlockedInscriptionsIds()
  const [loadedPage, setLoadedPage] = useState(false);
  const params: any = useParams();
  const history = useHistory();
  const [timeLeft, setTimeLeft] :any= useState(null);
  const [clientConfig, setClientConfig]: any = useState({});
  const [event, setEvent]: any = useState({});
  const [session, setSession]: any = useState({});
  const [tickets, setTickets]: any = useState([]);
  const [inscriptionsBlockedIds, setInscriptionsBlockedIds] = useState([]);
  const [validateBlockedInscriptions] =
    useValidateBlockedInscriptionsMutation();
  const [buttonFinishDisabled, setButtonFinishDisabled] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [totalPrice,setTotalPrice] = useState(0)

  const [createInscriptionsFromBlockedInscriptions] =
    useCreateInscriptionsFromBlockedInscriptionsMutation();
  useEffect(() => {
    checkInscriptionsBlocked();
  }, []);

  async function checkInscriptionsBlocked() {
    const inscriptionsBlocked = getBlockedInscriptionsIds()

    if (!inscriptionsBlocked || inscriptionsBlocked.length === 0) {
      returnHome();
    } else {
      const graphQlParams = {
        blockedInscriptionsIds: inscriptionsBlocked,
        clientCodename: params.clientCodename,
      };
      const res = await validateBlockedInscriptions({
        variables: graphQlParams,
      });

      try {
        if (
          res.data &&
          res.data.validateBlockedInscriptions &&
          res.data.validateBlockedInscriptions.inscriptionsBlocked &&
          res.data.validateBlockedInscriptions.inscriptionsBlocked.length > 0
        ) {
          const response = res.data.validateBlockedInscriptions;
          setInscriptionsBlockedIds(inscriptionsBlocked)
          setClientConfig(clientToClientConfig(response.client));
          setEvent(processEvent(response.event));
          setSession(sessionToSessionConfig(response.session));
          setTickets(
            processInscriptionsBlockedTickets(response.inscriptionsBlocked)
          );
          const diff = moment(response.inscriptionsBlocked![0].deleteDate).diff(moment())/1000
          setTimeLeft(diff)
          setLoadedPage(true);
        } else {
          returnHome();
        }
      } catch (error) {
        console.error(error)
        returnHome();
      }
    }
  }

  function processInscriptionsBlockedTickets(inscriptionsBlocked: any) {
    const tickets: any = [];
    let total = 0
    inscriptionsBlocked.forEach((inscriptionBlocked: any) => {
      const ticketFound = tickets.find(
        (ticket: any) => ticket.id === inscriptionBlocked.ticket.id
      );
      total += inscriptionBlocked.ticketSession && inscriptionBlocked.ticketSession.price ? inscriptionBlocked.ticketSession.price:0
      if (ticketFound) {
        ticketFound.quantity++;
      } else {
        tickets.push({
          id: inscriptionBlocked.ticket.id,
          name: inscriptionBlocked.ticket.name,
          description: inscriptionBlocked.ticket.description,
          quantity: 1,
          price: inscriptionBlocked.ticketSession && inscriptionBlocked.ticketSession.price ? inscriptionBlocked.ticketSession.price:0,
          ticketOffice: inscriptionBlocked.ticketSession && inscriptionBlocked.ticketSession.ticketOffice ? inscriptionBlocked.ticketSession.ticketOffice:false,
        });
      }
    });
    setTotalPrice(total)
    return tickets;
  }

  function returnHome() {
    removeBlockedInscriptionsIds()
    history.replace(`/${params.clientCodename}/events`);
  }

  async function finishInscriptionClicked() {
    const newTimeLeft = timeLeft
    setTimeLeft(null)
    setButtonFinishDisabled(true);
    const res = await createInscriptionsFromBlockedInscriptions({
      variables: {
        clientCodename: clientConfig.codename,
        blockedInscriptionsIds: inscriptionsBlockedIds,
      },
    });
    if (res.data && res.data.createInscriptionsFromBlockedInscriptions && !res.errors) {
      showToast("success", { message: "Inscripció realitzada correctament!" });
      history.replace("/mytickets");
    } else {
      setTimeLeft(newTimeLeft)
      showToast("error", { message: "Alguna cosa ha anat malament!" });
      returnHome();
    }
  }

  const renderer = ({ minutes = 0, seconds = 0 }) => {
    const stringMinutes = String(minutes).length<2? `0${minutes}`:`${minutes}`
    const stringSeconds = String(seconds).length<2? `0${seconds}`:`${seconds}`
   return (
        <span>
          {stringMinutes}:{stringSeconds}
        </span>
      );
  };

  const renderTime = ({ remainingTime }:any) => {
    const formatted = moment.utc(remainingTime*1000).format('mm:ss')
    return (
      <div className="timer">
        <div className="value">{formatted}</div>
      </div>
    );
  };

  const formatNumber = (number:number) => {
    return new Intl.NumberFormat('ca-CA', { style: 'currency', currency: 'EUR' }).format(number)
  }

  return (
    <React.Fragment>
      {!loadedPage ? (
        <LoaderContainer />
      ) : (
        <React.Fragment>
          <Navbar clientConfig={clientConfig} />
          <DefaultBodyContainer clientConfig={clientConfig} footer={false}>
            <div className={styles.container}>
              <CardContainer className={styles.cardContainerTickets}>
                <CardContainer.Header className={styles.cardTimeContainer}>
                    <div className={styles.timerContainer}>
                    {timeLeft && 
                      <CountdownCircleTimer onComplete={() => returnHome()} strokeWidth={5} size={60} isPlaying duration={timeLeft} colors={clientConfig.mainButtonBackgroundColor ?? colors.navbar.backgroundColor}>
                        {renderTime}
                      </CountdownCircleTimer>
                      }
                    </div>
                    <Line color="lightgray"/>
                </CardContainer.Header>
                <CardContainer.Header className={styles.cardTitleContainer}>
                  <div className={styles.totalTitle}>Total</div>
                  <div className={styles.totalQuantity}>{`${formatNumber(totalPrice)}`} {tickets[0].ticketOffice && `Taquilla`}</div>
                </CardContainer.Header>
                <CardContainer.Body className={styles.bodyCardContainer}>
                  <div className={styles.subtitleCardBody}>Entrades</div>
                  {tickets.map((ticket: any, index: number) => (
                    <div className={styles.ticketCardContainer} key={index}>
                      <div className={styles.ticketCardInfoContainer}>
                        <div>
                          <div>
                            <span>{ticket.name}</span>
                            <span style={{ marginLeft: 7 }}>
                              <IoTicketSharp
                                size={13}
                                style={{ marginRight: 2 }}
                              />
                              {ticket.quantity}
                            </span>
                          </div>
                          <div style={{ fontSize: 15, marginTop: 5 }}>
                          {`${formatNumber(ticket.price)}`} {ticket.ticketOffice && `Taquilla`}
                          </div>
                        </div>
                        <div>{`${formatNumber(ticket.price*ticket.quantity)}`} {ticket.ticketOffice && `Taquilla`}</div>
                      </div>
                      <Line color="lightgray" />
                    </div>
                  ))}
                </CardContainer.Body>

                <CardItemContainer className={styles.termsContainer}>
                  <div className={styles.termsContainer}>
                    <Checkbox
                      checked={termsChecked}
                      onClick={() => setTermsChecked(!termsChecked)}
                    />
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.termsLink}
                    >
                      Accepto els termes i condicions
                    </a>
                  </div>
                </CardItemContainer>
                <CardItemContainer className={styles.buttonFinishContainer}>
                  <StyledFinishButtonInscriptionCheckout
                    clientconfig={clientConfig}
                    variant="contained"
                    onClick={finishInscriptionClicked}
                    disabled={buttonFinishDisabled || !termsChecked}
                  >
                    Finalitzar
                  </StyledFinishButtonInscriptionCheckout>
                </CardItemContainer>
              </CardContainer>
              <CardContainer className={styles.cardContainerSession}>
                <CardContainer.Header className={styles.eventImageContainer}>
                  <img
                    src={event.poster}
                    alt="event"
                    className={styles.eventImage}
                  />
                </CardContainer.Header>
                <CardContainer.Body className={styles.summaryContainer}>
                  <div className={styles.eventName}>{event.name}</div>
                  <div className={styles.eventInfoContainer}>
                    <div className={styles.eventInfo}>{event.artistName}</div>
                    <div className={styles.eventInfo}>
                      {`${session.start.format("dddd , DD MMM YYYY HH:mm")}`}
                      <span style={{ marginRight: 8, marginLeft: 8 }}>•</span>
                      {`${session.end.format("dddd , DD MMM YYYY HH:mm")}`}
                    </div>
                    <div className={styles.eventInfo}>
                      {session.place} - {session.city} , {session.postalCode}
                    </div>
                    <div className={styles.eventInfo}>
                      {session.state} , {session.country}
                    </div>
                    {tickets.map((ticket: any, index: number) => (
                      <div key={index} className={styles.eventInfoTickets}>
                        <IoTicketSharp size={16} style={{ marginRight: 2 }} />
                        <span>{ticket.quantity}</span>
                        <span style={{ marginRight: 5, marginLeft: 5 }}>-</span>
                        <span>{ticket.name}</span>
                      </div>
                    ))}
                  </div>
                </CardContainer.Body>
              </CardContainer>
            </div>
          </DefaultBodyContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
