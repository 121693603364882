
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import colors from "../../../constants/colors";
import styles from "./CardContainer.module.css";

export default function CardContainer({
  className = "",
  children,
  ...rest
}: any) {
  return (
    <div className={`${styles.cardContainer} ${className}`} {...rest}>
      {children}
    </div>
  );
}

CardContainer.Header = ({ className = "", children, ...rest }: any) => (
  <div className={`${styles.cardHeaderContainer} ${className}`} {...rest}>
    {children}
  </div>
);

CardContainer.HeaderTitle = ({ className = "", children, ...rest }: any) => (
  <h3 className={`${styles.cardHeaderTitle} ${className}`} {...rest}>
    {children}
  </h3>
);

CardContainer.Body = ({ className = "", children, ...rest }: any) => (
  <div className={`${styles.cardBodyContainer} ${className}`} {...rest}>
    {children}
  </div>
);

CardContainer.InputGroup = ({ className = "", children, ...rest }: any) => (
  <div className={`${styles.inputGroup} ${className}`} {...rest}>
    {children}
  </div>
);

CardContainer.InputErrorLabel = ({
  className = "",
  children,
  ...rest
}: any) => (
  <div className={`${styles.inputErrorLabel} ${className}`} {...rest}>
    {children}
  </div>
);

CardContainer.Input = ({
  className = "",
  error = false,
  label = "Introdueix",
  helperText = "",
  children,
  ...rest
}: any) => (
  <TextField
    error={error}
    label={label}
    helperText={helperText}
    fullWidth
    {...rest}
  />
);

CardContainer.PasswordInput = ({
  helperText = "",
  error = false,
  showPassword = false,
  showPasswordFunction = function () {},
  label = "",
  ...rest
}) => {
  return (
    <TextField
      id="outlined-adornment-password"
      type={showPassword ? "text" : "password"}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={() => showPasswordFunction()}>
            <IconButton aria-label="toggle password visibility" edge="end">
              {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
      label={label}
      error={error}
      helperText={helperText}
    />
  );
};

CardContainer.Button = ({ className = "", disabled = false,children, ...rest }: any) => (
  <button
    className={`${styles.button} ${className}`}
    style={{
      backgroundColor: !disabled? colors.navbar.backgroundColor:'gray',
      color: colors.navbar.activeTextColor,
      cursor: !disabled? 'pointer': 'progress'
    }}
    {...rest}
    disabled={disabled}
  >
    {children}
  </button>
);

CardContainer.Link = ({ className = "", to = "", children, ...rest }: any) => (
  <Link to={to} className={`${styles.link} ${className}`} {...rest}>
    {children}
  </Link>
);
