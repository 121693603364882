import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import PageNotFound from '../views/errors/pageNotFound/PageNotFound';
import Home from '../views/public/home/Home';
import Cookies from '../views/public/cookies/Cookies';
import SidebarRoute from './SidebarRoute';
import NavbarRoute from './NavbarRoute';
import SignIn from '../views/auth/signIn/SignIn';
import SignUp from '../views/auth/signUp/SignUp';
import Dashboard from '../views/admin/dashboard/Dashboard';
import EventList from '../views/admin/event/eventList/EventList';
import CreateEvent from '../views/admin/event/createEvent/CreateEvent';
import ClientList from '../views/admin/clients/clientList/ClientList';
import ClientEvents from '../views/public/clientEvents/ClientEvents';
import EventInfo from '../views/public/eventInfo/EventInfo';
import { EventTickets } from '../views/public/eventTickets/EventTickets';
import InscriptionCheckout from '../views/public/inscriptionCheckout/InscriptionCheckout';
import ClientRedirect from '../views/public/clientEvents/ClientRedirect';
import MyTickets from '../views/user/myTickets/MyTickets';


export default function Routes(){
    const { t, i18n } = useTranslation();
//     const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

    function cookiesComponent(){
        return(
            <CookieConsent
                location="bottom"
                buttonText={t("COOKIES.BUTTON")}
                cookieName="cookies"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: '3px' }}
                expires={150}
            >
              {t("COOKIES.TEXT")} <a href="/cookies" className="d-block d-md-inline-block mx-md-2" style={{ color: "#FFD32D"  }}>{t("SEE_MORE")}</a>
            </CookieConsent>
        );
    }

    return (
            <BrowserRouter>
                <Switch>
                    {/* ADMIN ROUTES */}
                    <SidebarRoute exact path="/dashboard" component={Dashboard}/>
                    <SidebarRoute exact path="/admin/events" component={EventList}/>
                    <SidebarRoute exact path="/admin/events/create" component={CreateEvent}/>
                    <SidebarRoute exact path="/admin/clients" component={ClientList}/>

                    {/* PUBLIC ROUTES */}
                    <NavbarRoute exact path="/cookies" component={Cookies} />
                    <NavbarRoute path="/signup" component={SignUp} /> 
                    <NavbarRoute exact path="/signin" component={SignIn} />
                    <NavbarRoute exact protectedRoute={true} path="/mytickets" component={MyTickets} /> 
                    <NavbarRoute exact path={['/','/home']} component={Home} />

                    {/* CLIENT ROUTES */}
                    <Route exact path="/:clientCodename" component={ClientRedirect}/>
                    <Route exact path="/:clientCodename/events" component={ClientEvents}/>
                    <Route exact path="/:clientCodename/event/:eventId" component={EventInfo}/>
                    <Route exact path="/:clientCodename/event/:eventId/:sessionId" component={EventTickets}/>
                    <Route exact path="/:clientCodename/checkout" component={InscriptionCheckout}/>



                    <Route path="" component={PageNotFound} />

                </Switch>
                {cookiesComponent()}
            </BrowserRouter>
    );   
}