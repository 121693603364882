import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobileSize from "../../../../hooks/windowSize/useIsMobileSize";
import SidebarFullWidthReducer from "../../../../reducers/sidebar/SidebarFullWidthReducer";
import SidebarMobileOpened from "../../../../reducers/sidebar/SidebarMobileOpenedReducer";
import styles from "./Navbar.module.css";
import colors from "../../../../constants/colors";
import sizes from "../../../../constants/sizes";
import { HiOutlineMenuAlt2, HiOutlineMenuAlt3 } from "react-icons/hi";
import useBodySize from "../../../../hooks/bodySize/useBodySize";
import { useHistory, useLocation } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { AiOutlineLogout } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import useAuth from "../../../../hooks/auth/useAuth";

export default function Navbar({ sidebar = false, clientConfig = {} }: any) {
  const isMobileSize = useIsMobileSize();
  const showFullSize = useSelector((state: any) => state.sidebarFullWidth);
  const showMobile = useSelector((state: any) => state.sidebarMobileOpened);
  const bodySize = useBodySize();
  const [changingSidebar, setChangingSidebar] = useState(false);
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [menuUserOpened, setMenuUserOpened] = useState(false);

  function changeSidebarStatus() {
    if (!changingSidebar) {
      setChangingSidebar(true);
      if (isMobileSize) {
        dispatch(SidebarMobileOpened.actions[showMobile ? "hide" : "show"]);
      } else {
        dispatch(
          SidebarFullWidthReducer.actions[showFullSize ? "hide" : "show"]
        );
      }
      setTimeout(() => {
        setChangingSidebar(false);
      }, 300);
    }
  }

  function logout() {
    if (auth.user) {
      auth.signout(() => {
        setMenuUserOpened(false);
        if(clientConfig){
          history.replace(`/${clientConfig.codename}/events`);
        }else{
          history.replace("/");
        }
      });
    } else {
      setMenuUserOpened(false);
    }
  }

  const navbarContainerStyle = {
    backgroundColor:
      clientConfig.navbarBackgroundColor ?? colors.navbar.backgroundColor,
    color: clientConfig.navbarTextColor ?? colors.navbar.inactiveTextColor,
    height: sizes.navbar.height,
    width: sidebar ? bodySize : "100%",
    opacity: isMobileSize && showMobile ? 0.3 : 1,
    left:
      isMobileSize || !sidebar
        ? 0
        : showFullSize
        ? sizes.sidebar.sidebarFullWidth
        : sizes.sidebar.sidebarOnlyIcons,
  };

  function bodyClicked() {
    if (isMobileSize && showMobile) {
      dispatch(SidebarMobileOpened.actions.hide);
    }
  }

  function myTicketsClicked(){
    setMenuUserOpened(false)
    history.push('/myTickets')
  }

  return (
    <div
      className={styles.navbarContainer}
      style={navbarContainerStyle}
      onClick={() => bodyClicked()}
    >
      <div className={styles.navbarLeftContainer}>
        {sidebar ? (
          <button
            onClick={() => changeSidebarStatus()}
            className={styles.buttonMenu}
          >
            {showFullSize || (isMobileSize && showMobile) ? (
              //OPENED
              <HiOutlineMenuAlt3
                size={24}
                color={colors.navbar.activeTextColor}
                style={{ cursor: "pointer" }}
              />
            ) : (
              //CLOSED
              <HiOutlineMenuAlt2
                size={24}
                color={colors.navbar.activeTextColor}
                style={{ cursor: "pointer" }}
              />
            )}
          </button>
        ) : clientConfig.logo ? (
          <div
            className={styles.navbarTitleContainer}
            onClick={() => history.push(`/${clientConfig.codename}/events`)}
          >
            <img
              src={clientConfig.logo}
              alt={clientConfig.name}
              style={{ maxHeight: sizes.navbar.height-10}}
            />
            <span className={styles.clientTitle} style={{ color: clientConfig.navbarTextColor ?? colors.navbar.inactiveTextColor }}>{ clientConfig.name }</span>
          </div>
        ) : (
          <div
            className={styles.navbarTitleContainer}
            onClick={() => history.push("/")}
          >
            <label className={styles.navbarTitle}>TICKETPLAM</label>
            <img
              src="/images/ticketplam-navbar.png"
              alt="TICKETPLAM"
              style={{ height: 24 }}
            />
          </div>
        )}
      </div>
      <div className={styles.navbarRightContainer}>
        {auth.user ? (
          <React.Fragment>
            {auth.permissions.includes("admin_dashboard") && (
              <Button
                onClick={() => history.push("/dashboard")}
                className={styles.buttonIcon}
                style={{
                  borderBottom:
                    location.pathname === "/dashboard"
                      ? "2px solid #FFFFFF"
                      : "none",
                  color: colors.navbar.inactiveTextColor,
                  fontWeight: 900,
                }}
              >
                Àrea privada
              </Button>
            )}
            {/* <Button className={styles.buttonIcon}>
              <IoNotificationsOutline
                size={24}
                color={colors.navbar.inactiveTextColor}
              />
            </Button> */}
            {/* <Button className={styles.buttonIcon} onClick={() => history.push('cookies')}>
              <IoSettingsOutline
                size={24}
                color={colors.navbar.inactiveTextColor}
              />
            </Button> */}
            <Button
              className={styles.buttonIcon}
              ref={anchorRef}
              onClick={() => setMenuUserOpened(!menuUserOpened)}
              style={{
                borderBottom: menuUserOpened ? "2px solid #FFFFFF" : "none",
              }}
            >
              <img
                src={"/images/default-user.png"}
                alt="profile"
                className={styles.profileImage}
              />
              {!isMobileSize && (
                <label
                  style={{
                    color: colors.navbar.inactiveTextColor,
                    cursor: "pointer",
                    marginLeft: 10,
                    fontWeight: 900,
                  }}
                >
                  {auth.user.firstName}
                </label>
              )}
            </Button>
            <Menu
              anchorEl={anchorRef.current}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              open={menuUserOpened}
              onClose={() => setMenuUserOpened(false)}
            >
              <MenuItem onClick={() => myTicketsClicked()}>
                <label
                  style={{ marginRight: 10, marginTop: 3.5, cursor: "pointer" }}
                >
                  <IoTicketOutline size={18} />
                </label>
                <label style={{ cursor: "pointer" }}>Les meves entrades</label>
              </MenuItem>
              <MenuItem
                onClick={() => logout()}
                style={{
                  color: "#e22718",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ marginRight: 10, marginTop: 3.5, cursor: "pointer" }}
                >
                  <AiOutlineLogout size={18} />
                </label>
                <label style={{ cursor: "pointer" }}>Sortir</label>
              </MenuItem>
            </Menu>
          </React.Fragment>
        ) : (
          <div
            className={styles.linkContainer}
            onClick={() => history.push("/signin")}
          >
            <label className={styles.linkLabel}>
              <FaRegUserCircle
                style={{ display: "inline-block" }}
                size={18}
                color={colors.navbar.activeTextColor}
              />
            </label>
            <label
              className={styles.linkLabel}
              style={{ color: colors.navbar.activeTextColor }}
            >
              Iniciar sessió
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
