import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobileSize from "../../../../hooks/windowSize/useIsMobileSize";
import styles from "./Sidebar.module.css";
import colors from "../../../../constants/colors";
import { useHistory } from "react-router-dom";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import { IoChevronBackOutline, IoChevronDownOutline } from "react-icons/io5";
import useSidebarOptions from "../../../../hooks/sidebar/useSidebarOptions";
import SidebarMobileOpened from "../../../../reducers/sidebar/SidebarMobileOpenedReducer";
import useAuth from "../../../../hooks/auth/useAuth";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function Sidebar() {
  const showFullSize = useSelector((state: any) => state.sidebarFullWidth);
  const showMobile = useSelector((state: any) => state.sidebarMobileOpened);
  const isMobileSize = useIsMobileSize();
  const [options, setOptions]: any = useState(useSidebarOptions());
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useAuth()


  function getSidebar() {
    let className = "";
    if (isMobileSize) {
      className = `${styles.sidebarContainer} ${
        showMobile
          ? styles.sidebarContainerFullWidth
          : styles.sidebarMobileHidden
      }`;
    } else {
      className = `${styles.sidebarContainer} ${
        showFullSize
          ? styles.sidebarContainerFullWidth
          : styles.sidebarContainerIcons
      }`;
    }

    function openItem(item: any, index: number) {
      const new_options = [...options];
      new_options[index].opened = item.opened ? false : true;
      setOptions(new_options);
    }

    function selectChildren(indexItem: number, indexChildren: number) {
      setTimeout(() => {
        if (isMobileSize) {
          dispatch(SidebarMobileOpened.actions.hide);
        }
        history.push(options[indexItem].children[indexChildren].route);
      }, 100);
    }

    function getParentStyle(item: any, index: number) {
      const style: any = {
        textAlign: showFullSize || isMobileSize ? "start" : "center",
        display: "flex",
        justifyContent: showFullSize || isMobileSize ? "flex-start" : "center",
        alignItems: "center",
      };
      if (item.opened && !item.selected) {
        style.backgroundColor = item.opened ? "#dbdbdb" : "transparent";
      } else if (item.selected) {
        style.backgroundColor = colors.sidebar.parentBackgroundColorActive;
      }

      return style;
    }

    function logoClicked(){
      history.push("/")
      if (isMobileSize) {
        dispatch(SidebarMobileOpened.actions.hide);
      }
    }

    return (
      <div
        className={className}
        style={{
          backgroundColor: colors.sidebar.backgroundColor,
          color: colors.sidebar.inactiveTextColor,
        }}
      >
        <div className={styles.logoContainer} onClick={() => logoClicked()}>
          <label
            style={{ color: colors.sidebar.inactiveTextColor }}
            className={`${styles.companyTitle} ${
              !showFullSize && !isMobileSize ? styles.textHidden : ""
            }`}
          >
            TICKETPLAM
          </label>
          <img
            src="/images/ticketplam.png"
            alt="logo"
            className={styles.logoImage}
          />
        </div>
        <div className={styles.optionsContainer}>
          {auth.hasPermission('change_client') && 
            <FormControl fullWidth style={{ marginBottom:15, marginTop:5 }}>
            <InputLabel>Client</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={auth.client && auth.client.id? auth.client.id:null}
              label="Client"
              onChange={(e) => auth.changeClient(e.target.value)}
              size="small"
            >
              {auth.user.clients.map((client:any,index:number) => <MenuItem value={client.id} key={index}>{client.name}</MenuItem>)}              
            </Select>
          </FormControl>
          }
          {options.map((item: any, index: number) => (
            <div className={styles.option} key={index}>
              <div
                className={
                  item.selected
                    ? `${styles.optionButtonHeader} ${styles.optionButtonHeaderSelected}`
                    : styles.optionButtonHeader
                }
                style={getParentStyle(item, index)}
                onClick={() => openItem(item, index)}
              >
                {
                  <item.icon
                    size={20}
                    color={
                      !item.selected
                        ? colors.sidebar.inactiveTextColor
                        : colors.sidebar.parentTextColorActive
                    }
                  />
                }
                <div
                  style={{
                    color: !item.selected
                      ? colors.sidebar.inactiveTextColor
                      : colors.sidebar.parentTextColorActive,
                  }}
                  className={`${styles.labelOption} ${
                    !showFullSize && !isMobileSize ? styles.textHidden : ""
                  }`}
                >
                  {item.name}
                </div>

                <label
                  className={`${styles.arrowGroup} ${
                    !showFullSize && !isMobileSize ? styles.textHidden : ""
                  }`}
                >
                  {item.opened ? (
                    <IoChevronDownOutline
                      size={13}
                      color={
                        !item.selected
                          ? colors.sidebar.inactiveTextColor
                          : colors.sidebar.parentTextColorActive
                      }
                    />
                  ) : (
                    <IoChevronBackOutline
                      size={13}
                      color={
                        !item.selected
                          ? colors.sidebar.inactiveTextColor
                          : colors.sidebar.parentTextColorActive
                      }
                    />
                  )}
                </label>
              </div>

              {item.opened && (
                <div
                  className={`${styles.childOpened} ${
                    isMobileSize || showFullSize ? "" : styles.textHidden
                  }`}
                >
                  {item.children.map((children: any, indexChildren: number) => (
                    <div
                      key={indexChildren}
                      className={styles.childrenButtonHeader}
                      onClick={() => selectChildren(index, indexChildren)}
                      style={{
                        backgroundColor: children.selected
                          ? colors.sidebar.backgroundActiveColor
                          : "",
                      }}
                    >
                      {children.selected ? (
                        <RiCheckboxBlankCircleFill
                          size={18}
                          color={colors.sidebar.activeTextColor}
                        />
                      ) : (
                        <RiCheckboxBlankCircleLine
                          size={18}
                          color={colors.sidebar.inactiveTextColor}
                        />
                      )}
                      <label
                        className={styles.childrenLabel}
                        style={{
                          color: children.selected
                            ? colors.sidebar.activeTextColor
                            : colors.sidebar.inactiveTextColor,
                        }}
                      >
                        {children.name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return <div>{getSidebar()}</div>;
}
