import React from 'react';
import styles from'./pageNotFound.module.css'
import {FaQuestionCircle} from 'react-icons/fa'

export default function PageNotFound(){

    return(
        
        <React.Fragment>
            <div className={styles.body}>
            <div className={styles.mainbox}>
                <div className={styles.err}>4</div>
                <div className={styles.icon}><FaQuestionCircle /></div>
                <div className={styles.err2}>4</div>
                <div className={styles.msg}>Error, pàgina no trobada
                    <p><a href="/" className={styles.goToHome}>Anar a l'inici</a> </p>
                </div>
            </div>
        </div>
        </React.Fragment>
    );



}