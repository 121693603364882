import axios from "axios";
import { decrypt, encrypt } from "../../utils/cryptUtils";

export const API_BASE = "https://back.ticketplam.com"
export const INSCRIPTION_PRINT_ROUTE = `${API_BASE}/inscription/print/`
export const CLIENT_LOGO_IMAGE_BASE_ROUTE = `${API_BASE}/image/client/`;
export const CLIENT_BACKGROUND_IMAGE_BASE_ROUTE = `${API_BASE}/image/clientBackground/`;
export const EVENT_IMAGE_BASE_ROUTE = `${API_BASE}/image/event/`;


export default function useApi() {
  const apiUrl = `${API_BASE}/api/`;
  const apiRoutes = {
    signin: "signin",
    signup: "signup",
    autologin: "autologin"
  };
  async function apiPost(endpoint: string, params: any, encryptParams = true) {
    const axiosParams = {params: encryptParams ? encrypt(params) : params};
    console.log("params",axiosParams)
    const res: any = await axios.post(`${apiUrl}${endpoint}`, axiosParams);
    const response = encryptParams ? decrypt(res.data.response) : res.data;
    console.log('res',response)

    return response;
  }
  return {
    apiPost,
    apiRoutes,
    apiUrl,
  };
}
