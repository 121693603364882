import CryptoJS from "crypto-js";

export const CRYPT_KEY :any= "3xmNw7B2w7DfLNvsmeU0bKGU9BUVJ96e";

export function encrypt(data: any) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), CRYPT_KEY, {
    keySize: 128 / 8,
    iv: CRYPT_KEY,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decrypt(data: any) {
  return JSON.parse(
    CryptoJS.enc.Utf8.stringify(
      CryptoJS.AES.decrypt(data, CRYPT_KEY, {
        keySize: 128 / 8,
        iv: CRYPT_KEY,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
    )
  );
}
