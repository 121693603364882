export default function SidebarMobileOpened(
  state: boolean = false,
  action: any
) {
  switch (action.type) {
    case "SHOW_SIDEBAR_MOBILE":
      return true;
    case "HIDE_SIDEBAR_MOBILE":
      return false;
    default:
      return state;
  }
}

SidebarMobileOpened.actions = {
  show: { type: "SHOW_SIDEBAR_MOBILE" },
  hide: { type: "HIDE_SIDEBAR_MOBILE" },
};
