import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import styles from "./LoaderContainer.module.css";
import React from "react";

export default function LoaderContainer() {
  return (
    <React.Fragment>
      <div
        className={styles.loaderBackground}
        onClick={(event) => event.preventDefault()}
      >
        <Loader type="TailSpin" color="#337DAE" height={120} width={120} />
      </div>
    </React.Fragment>
  );
}
