export default function AuthUserClientReducer(
    state: any = null,
    action: any
  ) {
    switch (action.type) {
      case "SET_USER_CLIENT":
        return action.payload;
      case "REMOVE_USER_CLIENT":
        return null;
      default:
        return state;
    }
  }
  
  AuthUserClientReducer.actions = (roles:any = []) => ({
    setClient: { type: "SET_USER_CLIENT", payload: roles },
    removeClient: { type: "REMOVE_USER_CLIENT", payload: null },
  });