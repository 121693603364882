import { Link, Redirect, useHistory } from "react-router-dom";
import FooterContainer from "../../../components/containers/footerContainer/FooterContainer";
import styles from "./Home.module.css";
export default function Home() {
  const history = useHistory();

  return (
    <div className={styles.banner}>
      <div className={styles.infoHeader}>
        <h1 className={styles.pageTitle}>
          Registra les inscripcions dels teus esdeveniments amb Ticketplam!
        </h1>
        <h3 className={styles.pageSubtitle}>
          Et podem ajudar a controlar les inscripcions en els teus
          esdeveniments. Amb una àmplia quantitat de diferents configuracions
          per esdeveniment, ens podem adaptar al 100% a tu. Com a client tindràs
          la teva zona privada amb els teus diferents esdeveniments a punt per
          començar les inscripcions. Un cop arribi el dia de l'esdeveniment,
          llegeix les entrades de manera fàcil i efectiva amb la nostra app
          mòbil i comprova qui està entrant realment!
        </h3>
        <div className={styles.buttonsContainer}>
          <Link className={styles.buttonServices} to="/signin">
            Iniciar sessió
          </Link>
          <Link className={styles.buttonContact} to="/signup">
            Nou Usuari
          </Link>
        </div>
      </div>
      <div className={styles.imageDiv}>
        <img src="/images/bannerHome.png" alt="" className={styles.imageBanner} />
      </div>
    </div>
  );
}
