import React from "react";
import { Redirect, Route } from "react-router-dom";
import DefaultBodyContainer from "../components/containers/bodyContainer/DefaultBodyContainer";
import Navbar from "../components/navigation/header/navbar/Navbar";
import useAuth from "../hooks/auth/useAuth";

export default function NavbarRoute({
  component,
  protectedRoute = false,
  ...rest
}: any) {
  const auth = useAuth();
  const Component = component;
  return (
    <React.Fragment>
      {protectedRoute ? (
        <Route
          {...rest}
          render={({ location }) =>
            auth.user ? (
              <React.Fragment>
                <Navbar />
                <DefaultBodyContainer>
                  <Component />
                </DefaultBodyContainer>
              </React.Fragment>
            ) : (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: location },
                }}
              />
            )
          }
        />
      ) : (
        <Route
          {...rest}
          render={() => (
            <React.Fragment>
              <Navbar />
              <DefaultBodyContainer>
                <Component />
              </DefaultBodyContainer>
            </React.Fragment>
          )}
        />
      )}
    </React.Fragment>
  );
}
