import TextField from "@mui/material/TextField";

export default function DefaultInput({
  className = "",
  error = false,
  label = "Introdueix",
  helperText = "",
  fullWidth = true,
  ...rest
}: any) {
  return (
    <TextField
      error={error}
      label={label}
      helperText={helperText}
      fullWidth
      {...rest}
    />
  );
}
