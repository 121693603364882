import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import styles from "./TicketsCreateEventTab.module.css";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterMoment from "@mui/lab/AdapterMoment";
import DefaultInput from "../../../../../../components/parts/inputs/DefaultInput";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { HiTrash } from "react-icons/hi";
import Fab from "@mui/material/Fab";
import { RiAddFill } from "react-icons/ri";
export default function TicketsCreateEventTab({
  errors = [],
  tickets = [],
  setTickets,
  sessions = [],
}: any) {
  useEffect(() => {
    if (tickets.length === 0) {
      addTicket();
    }
  },[]);

  function addTicket() {
    const newTickets = [...tickets];
    const newTicket :any= { ...defaultDataTickets }
    newTicket.sessionsMax = []
    sessions.forEach((session:any,index:number) => {
      newTicket.sessionsMax.push({index,value:""})
    })
    newTickets.push({ ...newTicket });
    setTickets(newTickets);
  }

  function deleteTicket(index: number) {
    if (tickets.length > 1) {
      const newTickets: any = [...tickets];
      newTickets.splice(index, 1);
      setTickets(newTickets);
    }
  }

  function changeField(index: any, field: string, value: any) {
    const newTickets: any = [...tickets];
    newTickets[index][field] = value;
    setTickets(newTickets);
  }

  function changeSesionMax(indexTicket:number,indexSession:number,value:number){
    const newTickets: any = [...tickets];
    console.log(tickets)
    newTickets[indexTicket].sessionsMax[indexSession].value = value
    setTickets(newTickets);
  }

  return (
    <div>
      <Typography variant="h5" component="div" style={{ margin: "0 1%" }}>
        Llistat de tiquets
      </Typography>
      {tickets.map((ticket: any, index: number) => (
        <Card className={styles.cardContainer} key={index}>
          <CardContent>
            <div className={styles.inputsContainer}>
              <div className={styles.inputGroup}>
                <DefaultInput
                  required
                  label="Nom del tiquet"
                  style={{ width: "100%" }}
                  value={ticket.name}
                  error={errors[index] && errors[index].name ? true : false}
                  helperText={
                    errors[index] && errors[index].name
                      ? errors[index].name
                      : ""
                  }
                  onChange={(e: any) =>
                    changeField(index, "name", e.target.value)
                  }
                />
              </div>

              <div className={styles.inputGroup}>
                <DefaultInput
                  required
                  label="Descripció"
                  error={errors[index] && errors[index].description ? true : false}
                  helperText={
                    errors[index] && errors[index].description
                      ? errors[index].description
                      : ""
                  }
                  value={ticket.description}
                  onChange={(e: any) =>
                    changeField(index, "description", e.target.value)
                  }
                  maxRows={8}
                  minRows={5}
                  multiline
                />
              </div>
            </div>
            <div className={styles.sessionsContainer}>
              {sessions.map((session: any, indexSession: number) => (
                <DefaultInput
                  key={indexSession}
                  type="number"
                  label={`Màx insc sessió ${session.name}`}
                  style={{ width: "30%" }}
                  value={ticket.sessionsMax[indexSession].value}
                  onChange={(e:any) => changeSesionMax(index,indexSession,e.target.value)}

                />
              ))}
            </div>
          </CardContent>
          <CardActions className={styles.actionsContainer}>
            <IconButton title="Eliminar" onClick={(e) => deleteTicket(index)}>
              <HiTrash size={24} color={"#e22718"} />
            </IconButton>
          </CardActions>
        </Card>
      ))}

      <div
        style={{ display: "flex", marginTop: 20, justifyContent: "flex-end" }}
      >
        <Fab
          color="primary"
          className={styles.createButton}
          onClick={() => addTicket()}
          aria-label="edit"
        >
          <RiAddFill size={29} />
        </Fab>
      </div>
    </div>
  );
}

const defaultDataTickets = {
  name: "",
  description: "",
  sessionsMax: [],
};
