import { useSelector } from "react-redux";
import sizes from "../../constants/sizes";
import useIsMobileSize from "../windowSize/useIsMobileSize";
import useWindowSize from "../windowSize/useWindowSize";

export default function useBodySize(){
    const isMobileSize = useIsMobileSize()
    const showFullSize = useSelector((state:any) => state.sidebarFullWidth)
    const windowSize = useWindowSize()
    let width = windowSize.width;
   
    if(!isMobileSize){
        if(showFullSize){
            width = windowSize.width - (sizes.sidebar.sidebarFullWidth) 
        }else{
            width = windowSize.width - (sizes.sidebar.sidebarOnlyIcons)
        }
    }

    return width
}