export function sessionTicketToConfig(sessionTicket:any){
    console.log("session ticket",sessionTicket)
    const config :any= {
        maxInscriptions: sessionTicket.maxInscriptions,
        soldSessionTicket: sessionTicket.soldSessionTicket,
        description: sessionTicket.ticket ? sessionTicket.ticket.description:'',
        ticketId: sessionTicket.ticket ? sessionTicket.ticket.id:0,
        name: sessionTicket.ticket ? sessionTicket.ticket.name:'',
        added: 0,
    }
    config.allowMaxTickets = (config.maxInscriptions>0) ? (config.maxInscriptions-config.soldSessionTicket): -1

    return config
}