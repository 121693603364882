import { FormEvent, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import styles from "./SignUpModal.module.css";
import { Checkbox } from "semantic-ui-react";
import useApi from "../../../../hooks/api/useApi";
import useAuth from "../../../../hooks/auth/useAuth";
import { stringHasLettersAndNumbers, validateEmail } from "../../../../utils/validators";
import showToast from "../../../../utils/toastUtils";
import CardContainer from "../../../containers/cardContainer/CardContainer";
import Line from "../../../parts/line/Line";
import { GrClose } from "react-icons/gr";

export default function SignUpModal({setAuthModalOpened,setShowType,clientConfig = {}}:any) {
  const [loading,setLoading] = useState(false)
  const [errors, setErrors]: any = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastNames, setLastNames] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword,setShowPassword] = useState(false) 
  const {apiPost,apiRoutes} = useApi()
  const auth = useAuth()

  async function checkFormErrors() {
    const newErrors = [];

    if (!firstName || firstName.trim().length === 0) {
      newErrors.push("firstName");
    }

    if (!lastNames || lastNames.trim().length === 0) {
      newErrors.push("lastName");
    }

    if (!email || email.trim().length === 0 || !validateEmail(email)) {
      newErrors.push("email");
    }

    if (
      !password ||
      password.trim().length < 5 ||
      password.trim().length > 60 ||
      !stringHasLettersAndNumbers(password)
    ) {
      newErrors.push("password");
    }

    if (!termsAccepted) {
      newErrors.push("terms");
    }
    await setErrors(newErrors);
    return newErrors
  }

  async function signupFunction(e: FormEvent) {
    e.preventDefault();
    const newErrors = await checkFormErrors();

    if (newErrors.length === 0 && !loading) {
      setLoading(true)
      const params = {
        email,
        firstName,
        lastNames,
        password
      }
      const res = await apiPost(apiRoutes.signup,params)
      if(res.success && res.data.user){
        showToast("success",{message: "Usuari creat correctament"})
        auth.signin(res.data.user, () =>{
          setAuthModalOpened(false)
        })
      } else{
        showToast("error",{message: "Ja existeix un usuari amb aquest correu electrònic!"})
        setErrors([...errors,'email'])
        setLoading(false)
      }
    }
  }

  function signInClicked(){
      if(!loading){
          setShowType(0)
      }
  }

  return (
    <CardContainer className={styles.signUpContainer}>
        <div className={styles.closeIconContainer} onClick={(e:any) => setAuthModalOpened(false)}>
            <GrClose size={16}/>
        </div>
      <CardContainer.Header>
        <CardContainer.HeaderTitle>Crear un compte</CardContainer.HeaderTitle>
        <CardContainer.Body>
          <form>
            <CardContainer.InputGroup>
              <CardContainer.Input
                error={errors.includes("firstName")}
                helperText={errors.includes("firstName")? "Has d'introduir el teu nom!":""}
                value={firstName}
                label="Nom"
                onChange={(e: any) => setFirstName(e.target.value)}
              />
            </CardContainer.InputGroup>
            <CardContainer.InputGroup>
              <CardContainer.Input
                error={errors.includes("lastName")}
                helperText={errors.includes("lastName")? "Has d'introduir el teu cognom!":""}
                value={lastNames}
                label="Cognoms"
                onChange={(e: any) => setLastNames(e.target.value)}
              />
            </CardContainer.InputGroup>
            <CardContainer.InputGroup>
              <CardContainer.Input
                type="email"
                value={email}
                error={errors.includes("email")}
                label="Correu electrònic"
                onChange={(e: any) => setEmail(e.target.value)}
                helperText={errors.includes("email")? "Introdueix un email vàlid i no existent!":""}
              />
            </CardContainer.InputGroup>
            <CardContainer.InputGroup>
              <CardContainer.PasswordInput
                error={errors.includes("password")}
                label="Contrasenya"
                value={password}
                showPassword={showPassword}
                showPasswordFunction={() => setShowPassword(!showPassword)}
                onChange={(e: any) => setPassword(e.target.value)}
                helperText={errors.includes("password")? "La contrasenya no és vàlida!":"" }
              />
          

              <div className={styles.passwordRemember}>
                <h6 className={styles.passwordRememberTitle}>
                  La contrasenya ha de:
                </h6>
                <ul style={{ margin:0 }}>
                  <li>Tenir com a mínim 1 número i 1 lletra</li>
                  <li>Tenir entre 5 i 60 caràcters</li>
                </ul>
                <Line style={{marginTop:24}}/>
              </div>
            </CardContainer.InputGroup>
            <div className={styles.termsContainer}>
              <Checkbox
                checked={termsAccepted}
                onClick={() => setTermsAccepted(!termsAccepted)}
              />
              <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.termsLink}
              >
                Accepto els termes i condicions
              </a>
            </div>
            {errors.includes("terms") && (
                <CardContainer.InputErrorLabel>
                  <FiAlertTriangle
                    size={17}
                    style={{ display: "inline-block" }}
                  />
                  <label style={{ marginLeft: 5, display: "inline-block" }}>
                    Has d'acceptar els termes i condicions!
                  </label>
                </CardContainer.InputErrorLabel>
              )}

            <CardContainer.Button onClick={(e: FormEvent) => signupFunction(e)} disabled={loading}>
              Crear el meu compte
            </CardContainer.Button>
            <div onClick={(e:any) => signInClicked()} className={styles.signinLinkBottom} >
                Ja tens un usuari?
            </div>
          </form>
        </CardContainer.Body>
      </CardContainer.Header>
    </CardContainer>
  );
}
