import moment from "moment";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import AppInitReducer from "../../reducers/appInit/AppInitReducer";
import AuthUserClientReducer from "../../reducers/auth/AuthUserClient";
import AuthUserPermissionsReducer from "../../reducers/auth/AuthUserPermissionsReducer";
import AuthUserReducer from "../../reducers/auth/AuthUserReducer";
import AuthUserRolesReducer from "../../reducers/auth/AuthUserRolesReducer";
import LoadingAuthReducer from "../../reducers/auth/LoadingAuthReducer";
import useApi from "../api/useApi";
import { useBlockedInscriptionsIds } from "../blockedInscriptions/useBlockedInscriptions";

export default function useAuth() {
  const [cookies, setCookie, removeCookie] = useCookies(["jwtToken"]);
  const loading = useSelector((state:any) => state.loadingAuth)
  const user = useSelector((state: any) => state.authUser);
  const roles = useSelector((state: any) => state.authUserRoles);
  const permissions = useSelector((state: any) => state.authUserPermissions);
  const client = useSelector((state: any) => state.authUserClient);
  const dispatch = useDispatch();
  const { apiPost, apiRoutes } = useApi();
  const {removeBlockedInscriptionsIds} = useBlockedInscriptionsIds()




  async function signin(user: any, successFunction: Function, remember = true) {
    dispatch(LoadingAuthReducer.actions.loading)
    if (remember) {
      await setUserToken(user.jwtToken);
    }
    if(user.clients && user.clients.length>0){
      dispatch(AuthUserClientReducer.actions(user.clients[0]).setClient)
    }
    console.log(user)
    await dispatch(AuthUserReducer.actions(user).signin);
    if (user && user.roles) {
      const newRoles: any = [];
      const newPermissions: any = [];
      for (const role of user.roles) {
        newRoles.push(role.codename);
        for (const role_permissions of role.permissions) {
          if (!newPermissions.includes(role_permissions.codename)) {
            newPermissions.push(role_permissions.codename);
          }
        }
      }
      await dispatch(AuthUserRolesReducer.actions(newRoles).setRoles);
      await dispatch(
        AuthUserPermissionsReducer.actions(newPermissions).setPermissions
      );
      successFunction();
      removeBlockedInscriptionsIds()
      dispatch(LoadingAuthReducer.actions.setLoaded)
    }
  }

  async function signout(successFunction: Function) {
    dispatch(LoadingAuthReducer.actions.loading)
    await dispatch(AuthUserReducer.actions().signout);
    await dispatch(AuthUserRolesReducer.actions().removeRoles);
    await dispatch(AuthUserPermissionsReducer.actions().removePermissions);
    await dispatch(AuthUserClientReducer.actions().removeClient)
    await removeUserToken();
    await successFunction();
    removeBlockedInscriptionsIds()  
    dispatch(LoadingAuthReducer.actions.setLoaded)
  }

  async function autologin() {
    dispatch(LoadingAuthReducer.actions.loading)
    const token = getUserToken();
    if (token) {
      const res = await apiPost(apiRoutes.autologin, { jwtToken: token });
      if (res.success && res.data.user) {
        signin(res.data.user, () => {
          dispatch(AppInitReducer.actions.init);
          dispatch(LoadingAuthReducer.actions.setLoaded)
        });
      } else {
        signout(() => {
          dispatch(AppInitReducer.actions.init);
          dispatch(LoadingAuthReducer.actions.setLoaded)
        });
      }
    } else {
      dispatch(AppInitReducer.actions.init);
      dispatch(LoadingAuthReducer.actions.setLoaded)
    }
  }

  function getRouteAfterAuthenticate(route: any) {
    const new_route = route ?? {};

    if (route && route.pathname && route.pathname !== "/") {
      const blockedRoutesAfterLogin = ["signin", "signup"];

      let new_pathname = route.pathname;
      if (route.pathname.length > 0 && route[0] === "/") {
        new_pathname.slice(0, 1);
      }

      new_route.pathname = blockedRoutesAfterLogin.includes(route)
        ? "/"
        : route.pathname;
    } else {
      new_route.pathname = "/";
    }

    return new_route;
  }

  function getRouteAfterSignout() {
    return { pathname: "/login" };
  }

  function setUserToken(token: string) {
    removeCookie("jwtToken")
    const expireDate = moment().add(7, "day").toDate();
    setCookie("jwtToken", token, { path:'/',expires: expireDate });
  }

  function getUserToken() {
    return cookies["jwtToken"];
  }

  function removeUserToken() {
    return removeCookie("jwtToken",{path:'/'});
  }

  function hasPermission(permission:string){
    return permission.includes(permission)
  }

  function changeClient(client_id:number){
    const client = user.clients.find((client:any) => client.id === client_id)
    if(client){
      dispatch(AuthUserClientReducer.actions(client).setClient)
    }
  }

  return {
    loading,
    user,
    roles,
    permissions,
    client,
    changeClient,
    signin,
    signout,
    autologin,
    getRouteAfterAuthenticate,
    getRouteAfterSignout,
    getUserToken,
    hasPermission
  };
}
