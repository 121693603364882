import { useEffect, useState } from "react";
import { HiOutlineUserGroup } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { RiCalendarEventLine, RiShieldUserLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function useSidebarOptions(){

    const auth = useAuth()
    const [options,setOptions] = useState(sidebarOptions());
    const { pathname } = useLocation();

    useEffect(() => {
        const pathnameNoSlash = pathname.replaceAll("/","");
        const new_options = [...options]
        new_options.forEach((option:any) => {
            option.selected = false
            const selectedChildren = option.children.find((children:any) => children.selected);
            if(selectedChildren){
                selectedChildren.selected = false
            }
            const foundChildren = option.children.find((children:any) => children.route===pathname || children.route===pathnameNoSlash)
            if(foundChildren){
                option.selected = true
                option.opened = true
                foundChildren.selected = true
            }
        })
        setOptions(new_options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    function sidebarOptions(){
        const options :any=  [];
        //EVENTS
        options.push({
            order: 1,
            name: "Esdeveniments", 
            icon: RiCalendarEventLine,
            children:[
                {
                    name: "Llistat d'esdeveniments",
                    route: '/admin/events',
                    permissions: ["edit_events"]
                },
            ]
        })
         //CLIENTS
         options.push({
            order: 2,
            name: "Clients", 
            icon: RiShieldUserLine,
            children:[
                {
                    name: "Llistat de clients",
                    route: '/admin/clients',
                },
            ]
        })
        //USERS
        options.push({
            order: 2,
            name: "Usuaris", 
            icon: HiOutlineUserGroup,
            children:[
                {
                    name: "Llistat d'usuaris",
                    route: '/admin/users',
                },
            ]
        })
        //settings
        options.push({
            order: 2,
            name: "Configuració", 
            icon: IoSettingsOutline,
            children:[
                {
                    name: "Configuració d'usuari",
                    route: '/admin/user-settings',
                },
                {
                    name: "Configuració de client", 
                    route: "/admin/client-settings",
                    permissions: ['edit_client']
                }
            ]
        })
    
        const new_options :any= []
        options.forEach((option:any) =>{
            const optionPermission = option.children.find((children:any) =>{
                if(!children.permissions || children.permissions.length===0){
                    return true
                }
                const allowedPermissions = children.permissions.find((permission:any) => auth.hasPermission(permission))
                if(allowedPermissions.length>0){
                    return true
                }
                return false
            })
            if(optionPermission){
                new_options.push(option)
            }
        })

        return new_options
    }


    return options
}