import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import DefaultInput from "../../../../../../components/parts/inputs/DefaultInput";
import styles from "./InfoCreateEventTab.module.css";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@mui/material/Card";

export default function InfoCreateEventTab({
  errors = [],
  info = "",
  setInfo,
  poster = null,
  setPoster
}: any) {
  function changeInfoField(field: string, value: any) {
    const newInfo = { ...info };
    newInfo[field] = value;
    setInfo(newInfo);
  }

  function changeImage(event: any) {
    setPoster(event.target.files[0])
    changeInfoField("posterUrl", URL.createObjectURL(event.target.files[0]));
  }

  return (
    <div>
      <Typography variant="h5" component="div" style={{ margin: "0 1%" }}>
        Informació general
      </Typography>
      <Card className={styles.cardContainer}>
        <div className={styles.inputContainer}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={info.active}
                  onChange={(e: any) =>
                    changeInfoField("active", e.target.checked)
                  }
                />
              }
              label="Esdeveniment actiu"
            />
          </FormGroup>
        </div>
        <div className={styles.inputContainerFull}>
          <DefaultInput
            required
            label="Nom de l'esdeveniment"
            value={info.eventName}
            onChange={(e: any) => changeInfoField("eventName", e.target.value)}
            helperText={errors.eventName ? errors.eventName : ""}
            error={errors.eventName? true:false}
          />
        </div>
        <div className={styles.inputContainerFull}>
          <DefaultInput
            required
            label="Nom Artista"
            value={info.artistName}
            onChange={(e: any) => changeInfoField("artistName", e.target.value)}
            helperText={errors.artistName ? errors.artistName : ""}
            error={errors.artistName? true:false}
          />
        </div>

        <div className={styles.inputContainerFull}>
          <DefaultInput
            required
            label="Descripció"
            value={info.description}
            onChange={(e: any) =>
              changeInfoField("description", e.target.value)
            }
            maxRows={8}
            minRows={5}
            multiline
            helperText={errors.description ? errors.description : ""}
            error={errors.description? true:false}
          />
        </div>

        <div className={styles.inputContainer}>
          <h3>Cartell</h3>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={{ display: "none" }}
              onChange={(e: any) => changeImage(e)}
            />
            <Button variant="contained" component="span">
              Seleccionar una imatge
            </Button>
          </label>
        </div>
        {info.posterUrl && info.posterUrl.length > 0 && (
          <div className={styles.inputContainer}>
            <img
              src={info.posterUrl}
              alt="event poster"
              style={{ height: 200, maxWidth:'100%' }}
            />
          </div>
        )}
        {errors.poster && <div className={styles.errorPoster}>{errors.poster}</div>}
      </Card>
    </div>
  );
}

const Input = styled("input")({
  display: "none",
});
