export default function SidebarFullWidthReducer(
  state: boolean = true,
  action: any
) {
  switch (action.type) {
    case "SHOW_SIDEBAR_FULL_WIDTH":
      return true;
    case "SHOW_SIDEBAR_ICONS":
      return false;
    default:
      return state;
  }
}

SidebarFullWidthReducer.actions = {
  show: { type: "SHOW_SIDEBAR_FULL_WIDTH" },
  hide: { type: "SHOW_SIDEBAR_ICONS" },
};
