import React, { useEffect, useState } from "react";
import styles from "./EventInfo.module.css";
import { useHistory, useParams } from "react-router-dom";
import DefaultBodyContainer from "../../../components/containers/bodyContainer/DefaultBodyContainer";
import LoaderContainer from "../../../components/containers/loaderContainer/LoaderContainer";
import Navbar from "../../../components/navigation/header/navbar/Navbar";
import colors from "../../../constants/colors";
import { usePublicEventInfoQuery } from "../../../generated/graphql";
import {
  CLIENT_BACKGROUND_IMAGE_BASE_ROUTE,
  CLIENT_LOGO_IMAGE_BASE_ROUTE,
  EVENT_IMAGE_BASE_ROUTE,
} from "../../../hooks/api/useApi";
import CardItemContainer from "../../../components/containers/cardItemContainer/CardItemContainer";
import Line from "../../../components/parts/line/Line";
import moment from "moment";
import AuthModal from "../../../components/modals/auth/AuthModal";
import useAuth from "../../../hooks/auth/useAuth";
import { HiOutlineChevronRight } from "react-icons/hi";
import { StyledButtonEventInfo, StyledCardHeaderEventInfo, StyledHeaderEventInfo } from "./useEventInfoStyled";

export default function EventInfo() {
  const params: any = useParams();
  const history = useHistory();
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [event, setEvent]: any = useState([]);
  const [clientConfig, setClientConfig]: any = useState({});

  const { data, error, loading, refetch } = usePublicEventInfoQuery({
    variables: {
      eventId: Number(params.eventId),
      clientCodename: params.clientCodename,
    },
  });

  useEffect(() => {
    if(!loading){
      refetch()
    }
  },[])

  useEffect(() => {
    if (!loading && data) {
      const res = data.getEventSelected;
      if (!res.events || res.events.length !== 1) {
        history.replace("/");
      }
      setClientConfig({
        id: res.id,
        name: res.name,
        codename: params.clientCodename,
        logo: res.logo
          ? `${CLIENT_LOGO_IMAGE_BASE_ROUTE}${res.logo.filename}`
          : "",
        navbarBackgroundColor: res.navbarBackgroundColor,
        navbarTextColor: res.navbarTextColor,
        bodyTextColor: res.bodyTextColor,
        backgroundImage: res.backgroundImage
          ? `${CLIENT_BACKGROUND_IMAGE_BASE_ROUTE}${res.backgroundImage.filename}`
          : "",
        backgroundColor: res.backgroundColor,
        bodyBackgroundColor: res.bodyBackgroundColor,
        mainButtonBackgroundColor: res.mainButtonBackgroundColor,
        mainButtonTextColor: res.mainButtonTextColor,
      });
      const event: any = { ...res.events![0] };
      event.poster = EVENT_IMAGE_BASE_ROUTE + event.poster?.filename;
      setEvent(event);
      setLoadingInfo(false);
    } else if (!loading) {
      history.replace("/");
    }
  }, [data, error, loading]);

  function getCardContainerStyle(session: any, index: number) {
    const style = {
      borderBottomLeftRadius: index === event.sessions.length - 1 ? 15 : 0,
      borderBottomRightRadius: index === event.sessions.length - 1 ? 15 : 0,
      borderBottom:
        index === event.sessions.length - 1 ? "0.5px solid lightgray" : "none",
      backgroundColor: clientConfig.bodyBackgroundColor ?? "#FFFFFF",
      color: clientConfig.bodyColor ?? colors.body.textColor,
    };
    if (session.capacity > 0 && session.capacity - session.soldTickets <= 0) {
      style.backgroundColor = "lightgray";
      style.color = "gray";
    }
    return style;
  }



  return (
    <React.Fragment>
      {!loadingInfo ? (
        <React.Fragment>
          <Navbar clientConfig={clientConfig} />
          <DefaultBodyContainer style={{ padding: 0 }} footer={false}>
            <StyledHeaderEventInfo className={styles.headerBox} clientconfig={clientConfig} event={event}/>
            <div className={styles.imageContainer}>
              <img
                src={event.poster}
                alt={event.name}
                style={{
                  height: 250,
                  maxWidth: "100%",
                  borderRadius: 15,
                  zIndex: 2,
                  border: "1px solid lightgray",
                }}
              />
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.eventName}>{event.name}</div>
              <div className={styles.artistName}>{event.artistName}</div>
            </div>

            <div
              className={`${styles.infoContainer} ${styles.eventDescription}`}
            >
              {event.description}
            </div>
            <div className={styles.sessionsContainer}>
              <StyledCardHeaderEventInfo className={styles.sessionsCardHeader} clientconfig={clientConfig}>
                <h3 style={{ fontSize: 18 }}>Sessions</h3>
              </StyledCardHeaderEventInfo>
              {event.sessions &&
                event.sessions.map((session: any, index: number) => (
                  <CardItemContainer
                    key={index}
                    style={getCardContainerStyle(session, index)}
                  >
                    <CardItemContainer.FirstCol>
                      <div className={styles.firstColContainer}>
                        <div style={{ textTransform: "uppercase" }}>
                          {moment(session.start).format("DD MMM")} -{" "}
                          {moment(session.start).format("HH:mm")}
                        </div>
                        <Line />
                        {session.start !== session.end && (
                          <div style={{ textTransform: "uppercase" }}>
                            {moment(session.end).format("DD MMM")} -{" "}
                            {moment(session.end).format("HH:mm")}
                          </div>
                        )}
                      </div>
                    </CardItemContainer.FirstCol>
                    <CardItemContainer.MiddleCol>
                      <CardItemContainer.Image src={event.poster} alt="Event" />
                      <div className={styles.eventInfoContainer}>
                        <div className={styles.eventInfoMainContainer}>
                          <div className={styles.eventTextContainer}>
                            <label
                              className={`${styles.eventTextLabel} ${styles.titleLabel}`}
                            >
                              {session.name}
                            </label>
                          </div>
                          <Line />
                          <label
                            className={`${styles.eventTextLabel} ${styles.infoLabel}`}
                          >
                            <span className={styles.placeText}>
                              {session.place}
                            </span>
                            <span className={styles.placeText}>,</span>
                            <span className={styles.placeText}>
                              {session.city} {session.postalCode}
                            </span>
                            <span className={styles.placeText}>-</span>
                            <span className={styles.placeText}>
                              {session.state} {session.country}
                            </span>
                          </label>
                        </div>
                      </div>
                    </CardItemContainer.MiddleCol>
                    <CardItemContainer.LastCol>
                      {session.capacity > 0 &&
                      session.capacity - session.soldTickets <= 0 ? (
                        <div
                          className={`${styles.ticketsSoldOut} ${styles.lastButton}`}
                        >
                          Entrades esgotades
                        </div>
                      ) : (
                        <StyledButtonEventInfo
                          variant="contained"
                          onClick={(e) => history.push(`/${clientConfig.codename}/event/${event.id}/${session.id}`)}
                          clientconfig={clientConfig}
                        >
                          TROBAR ENTRADES{" "}
                          <HiOutlineChevronRight
                            style={{ marginLeft: 5 }}
                            size={18}
                            color={
                              clientConfig.mainButtonTextColor ??
                              colors.navbar.activeTextColor
                            }
                          />
                        </StyledButtonEventInfo>
                      )}
                    </CardItemContainer.LastCol>
                  </CardItemContainer>
                ))}
            </div>
          </DefaultBodyContainer>
        </React.Fragment>
      ) : (
        <LoaderContainer />
      )}
    </React.Fragment>
  );
}
