import styles from "./CardItemContainer.module.css";

export default function CardItemContainer({
  className = "",
  children,
  ...rest
}: any) {
  return (
    <div className={`${styles.container} ${className}`} {...rest}>
      {children}
    </div>
  );
}

CardItemContainer.FirstCol = ({ className = "", children, ...rest }: any) => (
  <div className={`${styles.firstRow} ${className}`} {...rest}>
    {children}
  </div>
);

CardItemContainer.MiddleCol = ({ className = "", children, ...rest }: any) => (
  <div className={`${styles.middleRow} ${className}`} {...rest}>
    {children}
  </div>
);

CardItemContainer.LastCol = ({ className = "", children, ...rest }: any) => (
  <div className={`${styles.lastRow} ${className}`} {...rest}>
    {children}
  </div>
);

CardItemContainer.Image = ({className = "",src = "",...rest}:any) => (
  <img src={src} {...rest} className={`${styles.image} ${className}`}/>
)