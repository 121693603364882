const sizes = {
    sidebar: {
        sidebarFullWidth: 220,
        sidebarOnlyIcons: 70
    },
    navbar:{
        height: 52
    }
}

export default sizes