import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LoaderContainer from "./components/containers/loaderContainer/LoaderContainer";
import useAuth from "./hooks/auth/useAuth";
import Routes from "./routes/routes";
import { createUploadLink } from 'apollo-upload-client';

function App() {
  const appInit = useSelector((state: any) => state.appInit);
  const auth = useAuth();

  const uploadLink = createUploadLink({
    uri: "https://back.ticketplam.com/graphql", //PROD
    //uri: "http://192.168.1.43:4005/graphql", //LOCAL
  });

  const apolloLink = setContext(async (_, { headers }) => {
    const token = await auth.getUserToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: apolloLink.concat(uploadLink),
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    if (!appInit && !auth.loading) {
      auth.autologin();
    }
  },[]);

  return (
    <div className="App">{!appInit ? <LoaderContainer /> : <ApolloProvider client={client}><Routes /></ApolloProvider>}</div>
  );
}

export default App;
